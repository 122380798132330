package views

import LoggedIn
import Router
import androidx.compose.runtime.*
import api.ApiInvoice
import backHeader
import client
import controls.RC
import controls.ResponsiveTable
import controls.classNames
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.dom.*
import tools.shortString

@Composable
fun Invoices() {
    val invoices = mutableStateListOf<ApiInvoice>()
    var incoming by remember { mutableStateOf(Router.queryParam("incoming") != null) }

    LoggedIn { user ->
        LaunchedEffect(incoming) {
            invoices.clear()
            client.invoices(onlyIcoming = incoming, onlyOutgoing = !incoming).collect { invoices.add(it) }
        }
        RC {
            backHeader("Invoices")
            Ul({ classNames("nav nav-tabs") }) {
                Li({ classNames("nav-item") }) {
                    A("", {
                        classNames(
                            if (incoming) "nav-link active" else "nav-link"
                        )
                        onClick { ev ->
                            incoming = true
                            ev.preventDefault()
                            ev.stopPropagation()
                        }
                    }) { Text("Incoming") }
                }
                Li({ classNames("nav-item") }) {
                    A("", {
                        classNames(
                            if (!incoming) "nav-link active" else "nav-link"
                        )
                        onClick { ev ->
                            incoming = false
                            ev.preventDefault()
                            ev.stopPropagation()
                        }

                    }) { Text("Outgoing") }
                }
            }
            ResponsiveTable {
                Thead {
                    Tr {
                        Th { Text("ID") }
                        Th { Text("created") }
                        Th { Text("amount") }
                        Th { Text("state") }
                    }
                }
                Tbody {
                    for (i in invoices) {
                        Tr({
                            style {
                                cursor("pointer")
                            }
                            onClick {
                                Router.push("/invoice/${i.guid}")
                            }
                        }) {
                            Td { Text("${i.guid}") }
                            Td { Text("${i.createdAt.shortString()}") }
                            Td { Text("${i.total.toPlainString()}") }
                            Td { Text("${i.state}") }
                        }
                    }
                }
            }
        }
    }
}