package views

import LoggedIn
import Router
import androidx.compose.runtime.*
import api.ApiMyWallets
import api.ApiOperation
import api.ApiUser
import backHeader
import client
import controls.*
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.cancel
import net.sergeych.sprintf.sprintf
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.whiteSpace
import org.jetbrains.compose.web.dom.*
import tools.shortString

@Composable
fun Operations() {
    val operations = mutableStateListOf<ApiOperation>()
    var myWallets by remember { mutableStateOf<ApiMyWallets?>(null) }
    var waiting by remember { mutableStateOf(true) }

    LoggedIn { user ->

        LaunchedEffect(true) {
            try {
                myWallets = client.wallets()
                client.operations().collect {
                    operations.add(it)
                    if (operations.size > 600) {
                        cancel()
                    }
                }
            } catch (x: CancellationException) {
                println("ops collector cancelled")
            } catch (t: Throwable) {
                t.printStackTrace()
            } finally {
                waiting = false
            }
        }

        val ww = myWallets

        RC {
            backHeader("Operations history")
            if (waiting)
                WaitMessage("loading operations")
            else if (operations.isEmpty()) {
                Di("alert alert-secondary mt-3") {
                    Text("no operations")
                }
            } else {
                ResponsiveTable {
                    Thead {
                        Tr {
                            Th { +"#" }
                            Th { +"when" }
                            Th { +"type" }
                            Th {
                                WithTooltip("rounded to 0.01") {
                                    +"amount"
                                }
                            }
                            Th { +"state" }
                            Th { +"notes" }
                        }
                    }
                    Tbody {

                        for (op in operations) {
                            Tr({
                                style {
                                    cursor("pointer")
                                }
                                onClick {
                                    Router.push("/operations/${op.id}")
                                }
                            }) {
                                Td { Text(op.id.toString()) }
                                Td({
                                    style {
                                        whiteSpace("nowrap")
                                    }
                                }) { Text(op.createdAt.shortString()) }
                                Td({
                                    style {
                                        whiteSpace("nowrap")
                                    }
                                }) {
                                    Text(op.type.toString())
                                    if (op.type == ApiOperation.Type.Transfer &&
                                        op.state == ApiOperation.State.Performed && ww != null
                                    ) {
                                        val (otherId, dir) = if (op.recipientWalletId in ww.ids)
                                            op.payerWalletId to "←"
                                        else if (op.payerWalletId in ww.ids)
                                            op.recipientWalletId to "→"
                                        else
                                            null to null
                                        otherId?.let {
                                            Text(" $dir " ?: "")
                                            UserByWalletId(it)
                                        } ?:
                                            Text("@${op.recipientWalletId}")
                                    }
                                }
                                Td({ classes("text-end") }) {
                                    var sign = ""
                                    Span({
                                        if (op.state == ApiOperation.State.Performed && ww != null) {
                                            classNames(
                                                if (op.recipientWalletId in ww.ids) {
                                                    sign = "+"
                                                    "text-success"
                                                } else if (op.payerWalletId in ww.ids) {
                                                    sign = "-"
                                                    "text-danger"
                                                } else ""
                                            )
                                        }
                                    }) {
                                        Text("$sign%.2f".sprintf(op.amount))
                                    }
                                }
                                Td { Text(op.state.toString()) }
                                Td({
                                    classNames("w-100")
                                }) { Text(op.description ?: "") }
                            }
                        }
                    }

                }
            }
        }
    }
}

@Composable
fun UserByWalletId(walletId: Long) {
    var other by remember { mutableStateOf<ApiUser?>(null) }
    LaunchedEffect(walletId) {
        kotlin.runCatching {
            other = client.userByWallet(walletId)
        }.getOrElse {
            it.printStackTrace()
        }
    }
    other?.let { user ->
        Text(user.name)
    } ?: Di("d-inline ms-1") { Di( "spinner-border spinner-border-sm") {} }
}