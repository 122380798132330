package controls

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.remember
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.SharedFlow
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import net.sergeych.mp_tools.globalDefer
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.seconds

object Toaster {
    @Suppress("unused")
    enum class Type(val cssClasses: String?) {
        DEFAULT(null),
        INFO("bg-info text-white"),
        SUCCESS("bg-success text-white"),
        WARNING("bg-warning"),
        ERROR("bg-danger text-white")
    }

    data class Item(
        val text: String,
        val title: String? = null,
        val type: Type = Type.DEFAULT,
        val stack: Boolean = true,
        val createdAt: Instant = Clock.System.now()
    ) {
        val isVisible: Boolean
            get() = Clock.System.now() < createdAt + 5.seconds
    }

    private val wflow = MutableSharedFlow<Item>(10)
    val flow: SharedFlow<Item> = wflow

    fun push(item: Item) {
        globalLaunch {
            wflow.emit(item)
        }
    }

    fun message(text: String, title: String? = null,stack: Boolean=true): Item =
        Item(text, title, Type.DEFAULT, stack).also { push(it) }
    fun info(text: String, title: String? = null,stack: Boolean=true): Item =
        Item(text, title, Type.INFO, stack).also { push(it) }

    fun error(text: String, title: String? = "Ошибка",stack: Boolean=true): Item =
        Item(text, title, Type.ERROR, stack).also { push(it) }

    fun warning(text: String, title: String? = null,stack: Boolean=true): Item =
        Item(text, title, Type.WARNING, stack).also { push(it) }


    @Composable
    fun contents() {
        val toasts = remember { mutableStateListOf<Item>() }

        if (toasts.isNotEmpty()) {
            Div({
                classes("toast-container", "position-fixed", "bottom-0", "start-0", "p-3")
                style { property("z-index", 11000) }
            }) {
                for (item in toasts) {
                    Div({
                        classes("toast", "show")
                        item.type.cssClasses?.let {
                            classes(it.split(" "))
                        }
                    }) {
                        item.title?.let { Div({ classes("toast-header") }) { Text(it) } }
                        Div({ classes("toast-body") }) { Text(item.text) }
                    }
                }
            }
        }

        LaunchedEffect("toasterFlowCollector") {
            flow.collect {
                toasts.removeAll { !it.isVisible || !it.stack }
                toasts.add(it)
            }
        }
        LaunchedEffect("toasterKiller") {
            while (true) {
                delay(500)
                toasts.removeAll { !it.isVisible }
            }
        }
    }

    fun error(t: Throwable) {
        error(t.toString())
    }

    fun <T>asyncCatching(onFinally: (()->Unit)?=null,f: suspend Toaster.()->T): Deferred<T?> =
        globalDefer {
            try {
                f()
            } catch (t: Throwable) {
                error("Непредвиденная ошибка: $t")
                null
            }
            finally {
                onFinally?.invoke()
            }
        }
    fun <T>launchCatching(onFinally: (()->Unit)?=null,f: suspend Toaster.()->T): Job =
        globalLaunch {
            try {
                f()
            } catch (t: Throwable) {
                error("Непредвиденная ошибка: $t")
                null
            }
            finally {
                onFinally?.invoke()
            }
        }

}