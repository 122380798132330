@file:UseSerializers(BigDecimalSerializer::class)
package api

import com.ionspin.kotlin.bignum.decimal.BigDecimal
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import tools.BigDecimalSerializer

@Serializable
data class ApiTransferRequest(
    val beneficiary: ApiUser,
    val amount: BigDecimal,
    val description: String,
    val invoice: ApiInvoice? = null
)