package controls

import androidx.compose.runtime.Composable
import api.ApiUser

@Composable
fun SelectUserGroup(label: String,user: ApiUser = ApiUser.empty, f: (ApiUser?)->Unit) {
    ButtonFieldGroup(
        user.name,
        label,
        attrs = { classNames("mb-2")}
    ) { show ->
        SelectUser(user) {
            f(it)
            show.value = false
        }
    }
}