import androidx.compose.runtime.*
import api.ApiUserDetails
import controls.*
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.dom.*
import views.Authentication
import views.Registration
import views.UserHome
import views.unaryPlus

private enum class HomeGuestMode {
    Landing, Registration, Authentication, Locked
}


@Composable
fun Home(user: ApiUserDetails?) {

            var mode by remember { mutableStateOf(HomeGuestMode.Landing) }
    if (user == null || mode == HomeGuestMode.Locked ) {
        Row {

            @Composable
            fun backButton() {
                Bn({
                    classes("btn-link")
                    onClick { mode = HomeGuestMode.Landing }
                }) {
                    Text("back")
                }

            }
            when (mode) {
                HomeGuestMode.Locked -> {
                    CenteredNarrowForm {
                        Div {
                            H1({classes("text-danger")}) {
                                +"Demo is turned off"
                            }
                            P {
                                +"""
                                    We are sorry to inform you that this demo is turned off. With any questions
                                    concerning demo reactivation and/or purchasing the indigo or other 
                                    blockchain or distributed trust technology
                                    please contact info at 8-rays.dev or visit developer site:
                                    """.trimIndent()
                                +" "
                                A(href="https://8-rays.dev") { +"https://8-rays.dev"}
                                +"."
                            }
                            P {
                                +"Yours sincerely,"
                                Br {  }
                                +"8-rays.dev team."
                            }
                        }
                    }
                }
                HomeGuestMode.Landing -> {
                    CenteredNarrowForm {
                        Row({ classes("mt-4","justify-content-center") }) {
                            Di("col col-auto") {
                                Img(src = "gold-logo1.png") {
                                    style {
                                        height(6.em)
                                    }
                                }
                            }
                            Di("col col-auto text-left") {
                                Di("fw-bold fs-1") { Text("INDIGO") }
                                Small {
                                    Text("Universa blockchain based digital transfers demo") }
                            }
                        }
                        Di("my-4 text-center fs-5") {
                            Text(
                                """
                                    | Identification is required. Please register or sign in. 
                            """.trimMargin()
                            )
                        }
                        Row {
                            Cl {
                                Bn({
                                    success()
                                    classNames("w-100")
                                    onClick { mode = HomeGuestMode.Registration }
                                }) {
                                    Icon.PersonAdd.render()
                                    Text("new account")
                                }
                            }
                            Cl {
                                Bn({
                                    classNames("w-100")
                                    primary()
                                    onClick { mode = HomeGuestMode.Authentication }
                                }) {
                                    Icon.Person.render()
                                    Text("existing account")
                                }
                            }
//                                Div {
//                                    Blink("/testdoc", { secondary("w-75") }) {
//                                        Text("Тестовый документ")
//                                    }
//                                }
                        }
                    }
                }

                HomeGuestMode.Registration -> Registration {
                    Bn({
                        classes("btn-secondary")
                        onClick { mode = HomeGuestMode.Authentication }
                    }) {
                        Text("I already have an account")
                    }
                    backButton()
                }

                HomeGuestMode.Authentication -> Authentication {
                    Bn({
                        classes("btn-secondary")
                        onClick { mode = HomeGuestMode.Registration }
                    }) {
                        Text("create new account")
                    }
                    backButton()
                }
            }

        }
    } else {
        UserHome(user)
    }

}

