package controls

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.attributes.readOnly
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLLabelElement
import tools.randomId
import views.unaryPlus

@Composable
fun ButtonFieldGroup(
    text: String,
    label: String? = null,
    labelContent: ContentBuilder<HTMLLabelElement>? = null,
    buttonText: String? = null,
    buttonContent: ContentBuilder<HTMLButtonElement>? = null,
    btnType: String = "btn-secondary",
    placeholderText: String? = null,
    icon: Icon? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable ElementScope<HTMLDivElement>.(MutableState<Boolean>)->Unit,
) {
    val showContent = remember { mutableStateOf(false) }
    val controlId: String = remember { randomId(17) }

    Div({
        attrs?.invoke(this)
    }) {
        Di("input-group") {
            Di("form-floating") {
                TextInput {
                    classes("form-control")
                    readOnly()
                    value(text)
                    placeholderText?.let { placeholder(it) }
                    onClick { showContent.value = !showContent.value }
                    id(controlId)
                }
                if (labelContent != null || label != null) {
                    Label(controlId) {
                        label?.let { +it }
                        labelContent?.invoke(this)
                    }
                }
            }
            Button({
                classNames("btn $btnType")
                onClick {
                    showContent.value = !showContent.value
                }
            }) {
                if( buttonContent != null ) {
                    buttonContent()
                }
                else {
                    icon?.render() ?: I({classes("bi", "bi-pencil-square")})
                    buttonText?.let { Text(it) }
                }
                buttonContent?.invoke(this)
            }
        }
        if( showContent.value ) {
            Di("mt-2") {
                this.content(showContent)
            }
        }
    }
//        TextInput {
//            classes("form-control")
//            placeholderText?.let { placeholder(it) }
//            value(text.value ?: "")
//            onInput {
//                val s = it.value.trim()
//                text.value = if(s == "") null else s
//                afterChange?.invoke()
//            }
//        }
//        Span({classes("input-group-text")}) {
//            I({classes("bi", "bi-search")})
//        }
//    }
}