package controls

import androidx.compose.runtime.*
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Span
import org.w3c.dom.HTMLInputElement
import tools.randomId
import tools.trimOrNull

@Composable
fun SearchField(
    text: MutableState<String?>,
    placeholderText: String? = null,
    icon: Icon? = null,
    onCancel: (()->Unit)? = null,
    afterChange: (() -> Unit)? = null
) {
    val editorId = remember { "ti_${randomId(7)}" }
    val searchText = remember { mutableStateOf(text.value?.trim() ?: "") }
    Di("input-group mb-3") {
        if( onCancel != null ) {
            Span({
                classes("input-group-text","pointer")
                    onClick { onCancel() }
            }) {
                Icon.XLarge.render()
            }
        }
        Input(type = InputType.Text) {
            id(editorId)
            classes("form-control")
            placeholderText?.let { placeholder(it) }
            value(searchText.value)
            onInput {
                searchText.value = it.value
                val s = it.value.trimOrNull()
                if (s != text.value) {
                    text.value = s
                    afterChange?.invoke()
                }
            }
        }
        Span({ classes("input-group-text") }) {
            icon?.render() ?: I({ classes("bi", "bi-search") })
        }
    }
    LaunchedEffect("setSearchFocus") {
        document.getElementById(editorId)?.let { (it as HTMLInputElement).focus() }
    }
}