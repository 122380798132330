package api

import net.sergeych.parsec3.ExceptionsRegistry


open class IndigoException(message: String, cause: Throwable? = null) : Exception(message, cause)

class StorageLimitException(text: String?=null): IndigoException(text ?: "лимит на хранение исчерпан")

class NotFoundException(text: String?=null): IndigoException(text ?: "объект не найден")

class NickNotAvailableException(text: String?) : IndigoException(text ?: "ник недоступен")

open class InternalError(text: String?,cause: Throwable?=null): IndigoException(text ?: "внутренняя ошибка", cause)

class NotAuthenticatedException(text: String?=null): IndigoException(text ?: "требуется аутентификация")

class PasswordRequiredException(): IllegalArgumentException("требуется пароль")

class AccessForbiddenException(t: String?=null) :
        IndigoException(t ?: "отказано в доступе")

class LoginInUseException(): IndigoException("логин недоступен")
class LoginFailed(): IndigoException("повторите попытку позже")

class InsufficientFundsException(): IndigoException("недостаточно средств на счету")
class NetworkException(t: String?=null): IndigoException(t ?:"сбой блокчейна")

class TransferAwaitsSigningException() : IndigoException("другая операция ожидает подписи")
class TransferInProgressException() : IndigoException("выполняется другой перевод")

val cloudocsExceptions = ExceptionsRegistry().apply {
    register { IndigoException(it ?: "неспецифицированная ошибка") }
    register { StorageLimitException(it) }
    register { NotFoundException(it) }
    register { NickNotAvailableException(it) }
    register { InternalError(it) }
    register { NotAuthenticatedException(it) }
    register { PasswordRequiredException() }
    register { AccessForbiddenException(it) }
    register { InsufficientFundsException() }
    register { NetworkException(it) }
    register { TransferAwaitsSigningException() }
    register { TransferInProgressException() }
}



