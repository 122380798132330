package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text

@Composable
fun WaitMessage(title: String, text: String? = null) {
    CenteredForm {
        Div({ classes("text-center") }) {
            H3 { Text(title + "\u2026") }
            Div({ classes("spinner-border", "tex-secondary", "mt-2") })
            text?.let { t -> Div { Text(t) } }
        }
    }
}

@Composable
fun WaitPanel(title: String, text: String? = null) {
    Div({ classes("text-center") }) {
        H3 { Text(title+ "\u2026") }
        Div({ classes("spinner-border", "mt-2") })
        text?.let { t -> Div { Text(t) } }
    }
}

@Composable
fun <T> WaitFor(value: T?, message: String, content: @Composable (T) -> Unit) {
    if (value == null) WaitPanel(message)
    else content(value)
}
