@file:UseSerializers(BigDecimalSerializer::class)
package api

import com.ionspin.kotlin.bignum.decimal.BigDecimal
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import tools.BigDecimalSerializer

@Serializable
class ApiOperation(
    val id: Long,
    val payerWalletId: Long?,
    val recipientWalletId: Long?,
    val amount: BigDecimal,
    val type: Type,
    val description: String? = null,
    val state: State = State.Pending,
    val stateChangedAt: Instant,
    val comment: String? = null,
    val createdAt: Instant,
    val referencedOperationId: Long? = null,
    val invoiceId: Long? = null
    ) {
    enum class State(val text: String) {
        Pending("pending"),
        Performed("performed"),
        Rejected("rejected"),
        AwaitingSignature("awaiting signature"),
        Cancelled("cancelled");

        override fun toString(): String = text
    }

    enum class Type(val text: String) {
        Minting("minting"),
        Transfer("transfer"),
        CreditExtension("credit granted");
        override fun toString(): String = text
    }
}

@Serializable
data class ApiRequestOperations(
    val beforeId: Long = Long.MAX_VALUE,
    val limit: Int = 100,
    val offset: Int = 0
)

@Serializable
data class GetOperationLogArgs(
    val operationId: Long,
    val beforeId: Long
)