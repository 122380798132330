package views

import androidx.compose.runtime.*
import client
import controls.*
import kotlinx.browser.document
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement

@Composable
fun Authentication(additionalContent: ContentBuilder<HTMLDivElement>) {
    var loginName by remember { mutableStateOf("testUser") }
    var loginError by remember { mutableStateOf<Boolean>(false) }
    var password by remember { mutableStateOf("12345qwert") }
    var inProgress by remember { mutableStateOf(false) }

    CenteredNarrowForm {
        if( inProgress ) {
            WaitPanel("authenticating")
        }
        else {
            H3 { Text("Existing user sign in") }
            val loginId = TextField(
                loginName,
                "Account name",
                message = if (loginError) "should not have trailing spaces" else null
            ) {
                loginName = it
                loginError = loginName.trim() != loginName
            }
            TextField(
                password,
                "Password",
                type = InputType.Password,
            ) {
                password = it
            }
            Bn({
                classNames("btn-primary me-2")
                if (loginError || loginName.isBlank() || password.isBlank() || inProgress)
                    disabled()
                onClick {
                    inProgress = true
                    globalLaunch {
                        if (!client.login(loginName, password))
                            Toaster.error("incorrect login and/or password")
                        inProgress = false
                    }
                }
            }) {
                Text("Log in")
            }
            additionalContent()
            LaunchedEffect("loginFocus") {
                (document.getElementById(loginId) as? HTMLInputElement)?.focus()
            }
        }
    }
}
