package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import tools.csplit

@Composable
fun CenteredForm(
    titleText: String? = null,
    title: (@Composable () -> Unit)? = null,
    contents: ContentBuilder<HTMLDivElement>,
) {
    Div({
        classes("position-fixed", "top-0", "bottom-0", "start-0", "end-0")
    }) {
        Div(
            {
                classes(
                    "position-absolute top-50 start-50 translate-middle container-sm shadow p-3 mb-5 bg-body rounded"
                        .csplit
                )
            }
        ) {
            titleText?.let { t -> H2 { Text(t) } }
            title?.let { it() }
            contents()
        }
    }
}

@Composable
fun CenteredNarrowForm(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    contents: ContentBuilder<HTMLDivElement>,
) {
    Div({
        classes("position-absolute top-0 bottom-0 start-0 end-0".csplit)
    }) {
        Div(
            {
                classes(
                    "position-absolute top-50 start-50 translate-middle container-sm shadow p-3 mb-5 bg-body rounded"
                        .csplit
                )
            }
        ) {
            Div({
                attrs?.invoke(this)
            }) {
                contents()
//                if (window.location.pathname != "/")
//                    A("#", {
//                        classes("mt-3")
//                        onClick { e ->
//                            Router.back()
//                            e.preventDefault()
//                        }
//                    }) { Text("<< назад") }
//
            }
        }
    }
}
