package views

import LoggedIn
import Router
import androidx.compose.runtime.*
import api.ApiUser
import api.TransferAwaitsSigningException
import api.TransferInProgressException
import backHeader
import client
import controls.*
import modalDialg
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.Text

@Composable
fun Transfer() {
    LoggedIn { user ->
        var beneficiary by remember { mutableStateOf(ApiUser.empty) }
        var amount by remember { mutableStateOf("") }
        var description by remember { mutableStateOf("") }
        var progress by remember { mutableStateOf(false) }

//        CenteredNarrowForm {
        if (progress) {
            WaitMessage("creating the transfer","please do not leave this page!")
        } else {
            backHeader("New transfer")

            Row {
                Col("md-6") {
                    ButtonFieldGroup(
                        beneficiary.name,
                        label = "beneficiary",
                        placeholderText = "select beneficiary",
                        attrs = { classNames("mb-2") }
                    ) { show ->
                        SelectUser(beneficiary) {
                            beneficiary = it ?: ApiUser.empty
                            show.value = false
                        }
                    }
                }
                Col("md-6") {
                    Di("mt-1") {
                        TextField(amount, "amount") {
                            if (numberOrNull(it) != null) amount = it
                        }
                    }
                }
                Col("12") {
                    TextField(description, "reason") { description = it }
                    Row({ classNames("gy-2 mt-3") }) {
                        Col("auto") {
                            Bn({
                                classNames("btn-primary")
                                val n = numberOrNull(amount)
                                if (n == null || n <= 0 || beneficiary.isEmpty)
                                    disabled()
                                onClick {
                                    progress = true
                                    globalLaunch {
                                        try {
                                            client.transfer(beneficiary, n!!, description)
                                            Toaster.info("transfer performed")
                                            Router.back()
                                        } catch (t: TransferAwaitsSigningException) {
                                            requestCancellingPendingTransfer()
                                        } catch (t: TransferInProgressException) {
                                            Toaster.warning("another transfer is in progress, please try again later")
                                        } catch (t: Throwable) {
                                            Toaster.error(t)
                                        } finally {
                                            progress = false
                                        }
                                    }
                                }
                            }) {
                                Text("Transfer")
                            }
                        }
                        Col("auto") {
                            Bn({
                                classNames("btn-outline-secondary")
                                onClick { Router.back() }
                            }) {
                                Text("cancel")
                            }
                        }
                    }
                }
            }
        }
    }
}

fun requestCancellingPendingTransfer() {
    modalDialg {
        lg()
        heading("Активна другая операция")
        body {
            Text(
                """
               | There is another operation in progress. You can wait for its completion, or try
               | to cancel it.
           """.trimMargin()
            )
        }
        footer {
            Bt("Await", "btn-primary") {
                Toaster.message("please try again later")
                close()
            }
            Bt("Cancel", "btn-warning") {
                Toaster.launchCatching {
                    client.cancelAwaitingOperations()
                    message("Pending operation cancelled. Please perform the transfer again.")
                }
                close()
            }
        }
    }

}
