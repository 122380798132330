import androidx.compose.runtime.*
import api.NotFoundException
import controls.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.flow.MutableStateFlow
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.info
import net.sergeych.mp_logger.warning
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.dom.Text
import views.*

typealias ModalHandler = @Composable (() -> Unit) -> Unit

object Router : LogTag("ROUTR") {

    var regex: Regex? = null
        private set

    var match: MatchResult? = null
        private set

    val defaultTitle = "INDIGO"

    data class Target(val title: String = defaultTitle, val content: @Composable () -> Unit)

    fun param(index: Int): String? =
        match?.groupValues?.get(index + 1)

    fun longParam(index: Int): Long =
        param(index)?.toLong() ?: throw NotFoundException()

    fun longParamOrNull(index: Int): Long? =
        kotlin.runCatching { param(index)?.toLong() }.getOrNull()

    val userRouting = listOf(
        "/" to Target { MainLayout { Home(it) } },
        "/new_invoice" to Target { NewInvoice() },
        "/new_transfer" to Target { Transfer() },
        "/operations/(\\d+)" to Target { Operation() },
        "/operations" to Target { Operations() },
        "/invoices" to Target { Invoices() },
        "/invoice/([^#/?]+)" to Target { ShowInvoice() },
        "/import" to Target { ImportFunds() },
//        "/(\\d+)?" to Target { DesignedLayout(PageContent.HOME) },
//        "/system" to Target { views.Home() },
//        "/buildings/new" to Target("Здания") { NewBuilding() },
//        "/buildings/(\\d+)" to Target("Зданиe") { EditBuilding() },
//        "/buildings" to Target("Здания") { ShowBuildings() },
//        "/company/buildings/(\\d+)" to Target("Здания" ) { CompanyBuilding() },
//        "/companies/new" to Target("Организация") { NewCompany() },
//        "/companies/(\\d+)(?:/([^#/?]+))?" to Target("Организация") { EditCompany() },
//        "/companies" to Target("Организации") { ShowCompanies() },
//        "/profile" to Target("Профиль") { UserProfile() },
//        "/building_counters/(\\d+)" to Target("Счетчики") { AdminCounters() },
//        "/admin" to Target("Сисадмин") { AdminSwitchboard() },
//        "/people(?:/([^#/?]+))?" to Target("Пользователи") { AdminUsers() },
//        "/househead/(\\d+)(?:/([^#/?]+))?" to Target("Главдом") { HeadHome() },
//        "/chat/(\\d+)?" to Target("Чат с УК") { DesignedLayout(PageContent.CHAT) },
//        "/employee_chats(?:/([^#/?]+))?" to Target("Чат с УК") { EmployeeChats() },
    ).map { (k, v) -> Regex("$k(?:\\?.*)?$") to v }

    val guestRouting = listOf(
        "/" to Target { MainLayout { Home(it) } },
    ).map { (k, v) -> Regex(k) to v }

    private var pathFlow = MutableStateFlow(targetFor(document.location?.pathname ?: "/"))

    init {
        globalLaunch {
            client.userFlow.collect {
                if (it != null)
                    pathFlow.value = targetFor(document.location?.pathname ?: "/")
            }
        }
        window.onpopstate = {
            document.location?.pathname?.let { loc ->
                pathFlow.value = targetFor(loc).also {
                    document.title = it.title
                }
            } ?: warning { "popstate without location: ${it.state}" }
        }
        document.title = pathFlow.value.title
    }

    private fun targetFor(url: String): Target {
        info { "selecting content for ${client.currentUser}: $url" }
        val routes = if (client.currentUser != null) userRouting else guestRouting
        for ((re, target) in routes) {
            re.matchEntire(url)?.let {
                match = it
                return target
            }
        }
        warning { "route not found: $url. current user is ${client.currentUser} switching to /" }
        return routes[0].second
    }

    fun push(url: String) {
        val t = targetFor(url)
        window.history.pushState(url, t.title, url)
        document.title = t.title
        pathFlow.value = t
    }

    fun back() {
        window.history.go(-1)
    }

    fun replace(url: String) {
        targetFor(url).let {
            window.history.replaceState(url, it.title, url)
            pathFlow.value = it
            document.title = it.title
        }
    }

    private val modalStack = mutableStateListOf<ModalHandler>()

    fun pushModal(modalHandler: ModalHandler) {
        modalStack.add(modalHandler)
    }

    @Composable
    fun userContent() {
        var target by remember { mutableStateOf(pathFlow.value) }
        var lastUser by remember { mutableStateOf(client.currentUser) }
        var modalHandler by remember { mutableStateOf<ModalHandler?>(null) }


        Toaster.contents()
        // the logic is complex before modal can cause other modals to modify the stack, so
        // check that we have an active modal:
        modalHandler?.let { handler ->
            // we have a handler!
            println("-------------------hh")

            // render it and onclose:
            handler.invoke {
                // drop current modal, it well cause recompose
                modalHandler = null
                // and remove it from the stack: could be not the last already:
                modalStack.remove(handler)
            }
        } ?: run {
            // recompose with no current modal - pick if exists,
            // it will call recompose so code above will work:
            modalHandler = modalStack.lastOrNull()
        }
        target.content()


        LaunchedEffect("collectStatus") {
            client.userFlow.collect {
                if (lastUser != it) {
                    lastUser = it
                    val url = document.location?.let { it.pathname + it.search + it.hash } ?: ""
                    replace(url)
                }
            }
        }

        LaunchedEffect("collectFlow") {
            pathFlow.collect { target = it }
        }
    }

    fun queryParam(name: String): String? =
        Regex("[?&]$name=([^&]+)").find(window.location.search)?.groups?.get(1)?.value
}


fun modalDialg(title: String? = null, f: DialogScope.() -> Unit) {
    try {
        Router.pushModal { doClose ->
            Dialog(title) {
                f()
                onClose(doClose)
            }
        }
    } catch (t: Throwable) {
        console.error("ошибка при создании модального диалога: $t")
        t.printStackTrace()
    }
}

fun confirm(
    text: String, title: String? = null,
    yesText: String = "Да",
    noText: String = "No",
    yesVariant: Variant = Variant.Primary,
    noVariant: Variant = Variant.Secondary,
    icon: Icon? = null,
): Deferred<Boolean> {
    val done = CompletableDeferred<Boolean>()
    try {
        Router.pushModal { doClose ->
            Dialog {
                title?.let {
                    heading(it)
                }
                body {
                    Di("container-fluid") {
                        Row {
                            icon?.let { ic ->
                                Di("col-auto") {
                                    ic.render({ classes("fs-1") })
                                }
                            }
                            Di("col") { Text(text) }
                        }
                    }
                }
                footer {
                    Bn({
                        classes(yesVariant.buttonClass)
                        onClick {
                            close()
                            done.complete(true)
                        }
                    }) {
                        Text(yesText)
                    }
                    Bn({
                        classes(noVariant.buttonClass)
                        onClick {
                            close()
                            done.complete(false)
                        }
                    }) {
                        Text(noText)
                    }
                }
                onClose {
                    if (done.isActive) done.complete(false)
                    doClose()
                }
            }
        }
    } catch (t: Throwable) {
        t.printStackTrace()
        done.completeExceptionally(t)
    }
    return done
}

@Suppress("unused")
fun withConfirm(
    text: String, title: String? = null,
    yesText: String = "Да",
    noText: String = "Нет",
    yesVariant: Variant = Variant.Primary,
    noVariant: Variant = Variant.Secondary,
    icon: Icon? = null,
    onConfirmed: () -> Unit,
) {
    globalLaunch {
        confirm(text, title, yesText, noText, yesVariant, noVariant, icon).await().let {
            if (it) onConfirmed()
        }
    }
}

@Suppress("unused")
suspend fun waitConfirm(
    text: String, title: String? = null,
    yesText: String = "Да",
    noText: String = "No",
    yesVariant: Variant = Variant.Primary,
    noVariant: Variant = Variant.Secondary,
    icon: Icon? = null,
): Boolean {
    return confirm(text, title, yesText, noText, yesVariant, noVariant, icon).await()
}

