package views

import LoggedIn
import Router
import androidx.compose.runtime.*
import api.ApiInvoice
import api.ApiUser
import api.ApiUserDetails
import backHeader
import client
import com.ionspin.kotlin.bignum.decimal.BigDecimal
import controls.*
import kotlinx.browser.document
import net.sergeych.mp_logger.Log
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.attributes.colspan
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import tools.bd

fun testInvoice(user: ApiUserDetails): ApiInvoice {
    return ApiInvoice(
        user.asUser,
//        customer = ApiUser("test user", id=2),
        customer = ApiUser.empty,
        positions = listOf(
            ApiInvoice.Position("Chicken meat, 100kg pack", 1.bd, 751.bd),
            ApiInvoice.Position("Lemons, 50kg pack", 1.bd, 238.bd),
        ).also { if( it.isEmpty()) throw RuntimeException("empty test customer")},
        state = ApiInvoice.State.AwaitPayment
    )
}

@Composable
fun NewInvoice() {
    var waitMessage by mutableStateOf<String?>(null)

    LoggedIn { user ->

        waitMessage?.let { message ->
            WaitMessage("processing the invoice", message)
        } ?: run {

//        var invoice by remember { mutableStateOf(ApiInvoice(user.asUser)) }
            EditInvoice(testInvoice(user), "New invoice:") { i ->
                if (i != null) {
                    globalLaunch {
                        try {
                            Log.connectConsole(Log.Level.DEBUG)
                            waitMessage = "creating the smartcontract"
                            client.registerInvoice(i) {
                                println("callback called")
                                waitMessage = "signing and posting to the blockchain"
                                println("Fine")
                            }
                            Toaster.message("Invoice issued")
                            Router.push("/")
                        } catch (t: Throwable) {
                            t.printStackTrace()
                            Toaster.error("Error issuing the invoice: $t")
                        }
                        finally {
                            waitMessage = null
                        }
                    }
                } else
                    Router.push("/")
            }
        }
    }
}

@Composable
fun EditInvoice(invoice: ApiInvoice, title: String, onDone: (ApiInvoice?) -> Unit) {

//    var invoiceTitle by mutableStateOf(invoice.title)
    var customer by mutableStateOf(invoice.customer)
    val positions = mutableStateListOf<ApiInvoice.Position>(*invoice.positions.toTypedArray())
    var newPosition by mutableStateOf(ApiInvoice.Position.empty.copy())

    RC {
        backHeader(title)
    }
    RC("mt-3") {
        ResponsiveTable {
            Thead {
                Tr {
                    Th { Text("name") }
                    Th { Text("amount") }
                    Th { Text("price") }
                    Th({classNames("d-none d-sm-table-cell")}) { Text("subtotal") }
                    Td {}
                }
            }
            Tbody {
                positions.forEachIndexed { i, p ->
                    EditPosition(p, false) { new ->
                        println("at $i: $new")
                        if (new == null) {
                            positions.removeAt(i)
                            println("removing at $i")
                            for ((n, x) in positions.withIndex()) {
                                println("$n: $x")
                            }
                            newPosition = newPosition.copy()
                        } else {
                            positions[i] = new.copy()
                        }
                    }
                }
                EditPosition(newPosition, true) { new ->
                    println("atNEW $new")
                    if (new != null) {
                        positions.add(new.copy())
                        newPosition = ApiInvoice.Position.empty.copy()
                        for ((i, x) in positions.withIndex()) {
                            println("$i: $x")
                        }
                    }
                }
                if (positions.isNotEmpty()) {
                    Tr({ classNames("border-dark border-top mt-2") }) {
                        Td({
                            colspan(3)
                        }) {
                            B { Text("TOTAL") }
                        }
                        Td {
                            B {
                                Text(positions.fold(BigDecimal.ZERO) { acc, it -> acc + it.cost }
                                    .toPlainString())
                            }
                        }
                        Td {}
                    }
                }
            }
        }
    }
    RC {
        ButtonFieldGroup(
            customer.name,
            "Customer:"
        ) { show ->
            SelectUser(customer) {
                customer = it ?: ApiUser("")
                show.value = false
            }
        }
    }

    RC {
        Row({ classNames("gy-2 mt-3") }) {
            Col("auto") {
                Bn({
                    classNames("btn-primary")
                    println("-- p1 ${positions.isEmpty()}")
                    println("-- p2 ${customer.isEmpty}: $customer")
                    if (positions.isEmpty() || customer.isEmpty)
                        disabled()
                    onClick {
                        onDone(
                            invoice.copy(
                                customer = customer,
                                positions = positions,
                                total = positions.fold(BigDecimal.ZERO) { acc, ip -> acc + ip.cost }
                            )
                        )
                    }
                }) {
                    Text("Issue")
                }
            }
            Col("auto") {
                Bn({
                    classNames("btn-outline-secondary")
                    onClick { onDone(null) }
                }) {
                    Text("cancel")
                }
            }
        }
    }
}

fun numberOrNull(value: String): BigDecimal? =
    try {
        BigDecimal.parseString(value).let {
            if (it >= 0) it else null
        }
    } catch (t: Throwable) {
        null
    }

fun numberOrBlank(bd: BigDecimal): String =
    if (bd <= BigDecimal.ZERO) "" else bd.toPlainString()

@Composable
fun EditPosition(p: ApiInvoice.Position, newRecord: Boolean = false, f: (ApiInvoice.Position?) -> Unit) {
    var name by mutableStateOf(p.name)
    var qty by mutableStateOf(numberOrBlank(p.quantity))
    var price by mutableStateOf(numberOrBlank(p.unitPrice))

    fun total(): BigDecimal? {
        val n = numberOrNull(qty)
        val pr = numberOrNull(price)
        return if (n != null && n > 0 && pr != null && pr > 0) n * pr else null
    }

    fun stateChanged() {
        if (!newRecord && name.isNotBlank() && total() != null) {
            f(ApiInvoice.Position(name, BigDecimal.parseString(qty), BigDecimal.parseString(price)))
        }
    }

    Tr {
        Td({
            style { width(auto) }
        }) {
            val id = TextField(name, "name") {
                name = it
                stateChanged()
            }
            if (newRecord) {
                LaunchedEffect("newInvoicePosFocus") {
                    (document.getElementById(id) as? HTMLInputElement)?.focus()
                }
            }
        }
        Td {
            TextField(qty, "amount") { x ->
                if (numberOrNull(x) != null) {
                    qty = x
                    stateChanged()
                }
            }
        }
        Td {
            TextField(price, "unit price") { x ->
                if (numberOrNull(x) != null)
                    price = x
                stateChanged()
            }
        }
        Td({classNames("d-none d-sm-table-cell")}) {
            total()?.let {
                Di("mt-4") { B { Text(it.toPlainString()) } }
            }
        }
        Td {
            Bn({
                classNames("mt-3 btn-xs")
                if (newRecord) classNames("btn-outline-success btn-sm")
                else classNames("btn-outline-danger btn-sm")
                if (newRecord && (total() == null || name.isBlank()))
                    disabled()
                onClick {
                    if (newRecord && total() != null) {
                        f(ApiInvoice.Position(name, BigDecimal.parseString(qty), BigDecimal.parseString(price)))
                    } else {
                        if (!newRecord) f(null)
                    }
                }
            }) {
                if (newRecord) Icon.PlusCircle.render({classNames("ms-1")})
                else Icon.XCircle.render({classNames("ms-1")})
            }
        }
    }
}





