@file:UseSerializers(BigDecimalSerializer::class)
package api

import com.ionspin.kotlin.bignum.decimal.BigDecimal
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import tools.BigDecimalSerializer

@Serializable
data class ApiWallet(
    val id: Long = 0,
    val balance: BigDecimal = BigDecimal.ZERO,
    val type: Type
) {
    enum class Type(val text: String) {
        Checking("расчетный"),
        Credit("кредитны")
    }
}

@Serializable
data class ApiMyWallets(
    val checking: ApiWallet,
    val credit: ApiWallet
) {
    constructor(ww: Collection<ApiWallet>) : this(
        ww.first { it.type == ApiWallet.Type.Checking },
        ww.first { it.type == ApiWallet.Type.Credit }
    )

    val ids by lazy { setOf(checking.id, credit.id) }
}

