@file:OptIn(ExperimentalJsExport::class)
@file:Suppress("unused")

package platform

import net.sergeych.unikrypto.Unicrypto
import org.khronos.webgl.Uint8Array
import kotlin.js.Date
import kotlin.js.Promise

@JsExport
class SymmetricKeyParams(val keyBytes: Uint8Array)

@JsExport
class PrivateKeyParams(val strength: Int)

@JsExport
class SigningOptions(
    val salt: ByteArray? = null,
    val salLength: Int? = null,
    val mgf1Hash: String = "sha1",
    val pssHash: String = "sha3_384"
)

@JsExport
class OAEPOptions(
    val seed: Uint8Array? = null, // optional, default none
    val mgf1Hash: String? = null,
    val oaepHash: String? = null
)

class RoleSimpleOptions(
    val keys: List<Unicrypto.PublicKey>? = undefined,
    val addresses: List<Unicrypto.KeyAddress>? = undefined,
)

class TransactionPackOptions(
    val subItems: List<Uint8Array>?,
    val referencedItems: List<Uint8Array>?,
    val tags: Map<String, Uni.HashId>?,
    val keys: List<Uint8Array>?
)

@JsExport
class SignedByOptions(
    val publicKey: Unicrypto.PublicKey? = undefined,
    val address: Unicrypto.KeyAddress? = undefined
)

external interface Role {
    var name: String
}

@Suppress("unused")
@JsModule("universa-core")
@JsNonModule
external class Uni {
    class HashId {

    }

    class KeyRecord(keyPacked: Uint8Array) {
    }

    class RoleSimple(name: String, options: RoleSimpleOptions) : Role {
        override var name: String
    }

    class RoleLink(name: String, targetName: String): Role {
        override var name: String
        val targetName: String
    }

    class State() {
        val data: dynamic
        var expiresAt: Date
        var owner: Role
        var creator: Role
        var roles: MutableMap<String, Role>
    }

    class Definition() {
        val data: MutableMap<String, Any?>
        var issuer: Role
    }

    class UniversaContract() {
        val apiLevel: Int
        val definition: Definition
        val state: State
        val transactional: dynamic
    }

    class Capsule() {
        val contract: UniversaContract
    }

    class Contract() {
        val version: Int
        val capsule: Capsule

        fun isSignedBy(options: SignedByOptions): Promise<Boolean>


        fun packData()
    }

    class TransactionPack(contractPacked: Uint8Array, options: TransactionPackOptions? = definedExternally) {
        val contract: Contract

        fun pack(): Promise<Uint8Array>
        fun sign(key: Unicrypto.PrivateKey): Promise<Unit>

        companion object {
            fun unpack(packed :Uint8Array): TransactionPack
        }
    }

    class Compound(tpack: TransactionPack) {
        companion object {
            fun unpack(bin: Uint8Array): Compound
        }

        fun pack(): Uint8Array
        fun getTags(): List<String>
        fun getTag(tag: String): Promise<TransactionPack?>
        fun getItem(hashId: HashId): Promise<TransactionPack?>
        fun sign(key: Unicrypto.PrivateKey): Promise<Unit>
    }
}

