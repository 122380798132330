package platform

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import net.sergeych.unikrypto.*
import kotlin.time.Duration.Companion.days

interface SmartContract {

    suspend fun isSignedBy(address: KeyAddress): Boolean
    suspend fun isSignedBy(key: PublicKey): Boolean {
        return isSignedBy((key.id as AddressId).address)
    }
    suspend fun isSignedBy(id: KeyIdentity): Boolean {
        return id is AddressId && isSignedBy(id.address)
    }

    suspend fun addSignature(key: PrivateKey)

    fun addSigningKey(key: PrivateKey)

    val state: Map<String,Any?>
    val definition: Map<String,Any?>
    val transactional: Map<String,Any?>

    fun <R>updateState(f: (MutableMap<String,Any?>)->R): R
//    var definition: BossStruct
//    var transactional: BossStruct

    suspend fun pack(): ByteArray

    var expiresAt: Instant

    fun createRole(name: String, ids: Collection<AddressId> )

    fun createRole(name: String,vararg privateKeys: PrivateKey) {
        createRole(name, privateKeys.map {
            addSigningKey(it)
            it.id as AddressId
        })
    }
    fun createRole(name: String,vararg publicKeys: PublicKey) {
        createRole(name, publicKeys.map {
            it.id as AddressId
        })
    }
    fun createRole(name: String,vararg ids: KeyIdentity) {
        createRole(name, ids.map { it as AddressId })
    }

    suspend fun seal()

    fun check(): String?

    fun createRoleLink(existingRole: String, newRole: String)
}

fun createSmartContractBy(privateKey: PrivateKey,
                          expiresAt: Instant = Clock.System.now() + 1000.days): SmartContract {
    val sc = createSmartContract()
    sc.expiresAt = expiresAt
    sc.createRole("issuer", privateKey)
    sc.createRoleLink("issuer", "creator")
    return sc
}
