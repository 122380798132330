package controls

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLLabelElement
import tools.randomId

@Composable
fun TextField(value: String,
              label: String?=null,
              labelContent: ContentBuilder<HTMLLabelElement>?=null,
              isValid: Boolean? = null,
              message: String? = null,
              type: InputType<String> = InputType.Text,
              setter: (String)->Unit): String {

    val controlId: String = remember { randomId(17) }

    Di("form-floating mb-3") {
        Input(type) {
            id(controlId)
            onInput { setter(it.value) }
            classes("form-control")
            value(value)
            if( isValid == true ) classes("is-valid")
            else if(isValid == false) classes("is-invalid")
            placeholder("введите значение")
        }
        Label(controlId) {
            label?.let { Text(it) }
            labelContent?.invoke(this)
        }
        if (message != null) {
            Div({
                when (isValid) {
                    true -> classes("valid-feedback")
                    false -> classes("invalid-feedback")
                    else -> classes("small")
                }
            }) {
                Text(message)
            }
        }
    }

    return controlId
}