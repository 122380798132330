import androidx.compose.runtime.*
import api.ApiUserDetails
import controls.Di
import controls.Icon
import controls.Row
import controls.classNames
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement

@Composable
fun backHeader(title: String) {
    H4 {
        Row {
            Di("col") {
                Icon.ArrowLeft.render({
                    classNames("me-2")
                    style { cursor("pointer") }
                    onClick { Router.back() }
                })
                Text(title)
            }
            Di("col text-end") {
                Img("gold-logo1.png") {
                    style { height(1.4.em) }
                    classNames("me-2")
                }
                Text(client.currentUser?.name ?: "")
            }
        }
        Hr {}
    }
}

@Composable
fun MainLayout(fluid: Boolean = false, f: @Composable ElementScope<HTMLDivElement>.(ApiUserDetails?) -> Unit) {
    var user by remember { mutableStateOf<ApiUserDetails?>(client.currentUser) }
    LaunchedEffect("layoutUser") {
        client.userFlow.collect {
            if (user != it) user = it
        }
    }
    Div({
        if (fluid) classes("container-fluid")
        else classes("container")
    }) {
//        if (window.location.pathname != "/") {
//            Di("position-relative") {
//                Di("position-absolute top-0 end-0") {
//                    A("", {
//                        onClick { ev ->
//                            Router.back()
//                            ev.stopPropagation()
//                            ev.preventDefault()
//                        }
//                    }) { Text("<< back") }
//                }
//            }
//        }
        f(user)
    }
}

@Composable
fun LoggedIn(fluid: Boolean = false, f: @Composable ElementScope<HTMLDivElement>.(ApiUserDetails) -> Unit) {
    MainLayout(fluid) { user ->
        Di("mt-2") {
            user?.let { f(it) } ?: Text("authorization required")
        }
        SideEffect { if (user == null) Router.push("/") }
    }
}
