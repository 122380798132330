package platform

import kotlinx.coroutines.await
import kotlinx.datetime.toJSDate
import kotlinx.datetime.toKotlinInstant
import net.sergeych.unikrypto.*
import org.khronos.webgl.Uint8Array
import kotlin.js.Date

val getKeys = js("Object.keys")
fun jsTypeOf(o: Any) = js("typeof o")
fun jsIsNull(o: Any) = js("o == null")

fun jsIsArray(o: Any) = js("Array.isArray(o)")

fun jsIsConstructorDate(o: Any) = js("o.constructor.name == 'Date'")
fun jsIsConstructorObject(o: Any) = js("o.constructor.name == 'Object'")

fun readObject(obj: dynamic): MutableMap<String, Any?> {
    fun readAny(obj: Any): Any {
        fun readArray(obj: dynamic): List<Any> {
            val list = mutableListOf<Any>()
            val total = obj.length - 1

            for (i in 0..total) {
                list += readAny(obj[i])
            }

            return list.toList()
        }

        val objType = jsTypeOf(obj)

        if (objType == "object" && jsIsNull(obj) != true) {
            if (jsIsConstructorDate(obj)) return (obj as Date).toKotlinInstant()
            if (jsIsArray(obj)) return readArray(obj)
            return readObject(obj)
        }
        return obj
    }

    var map = mutableMapOf<String, Any?>()
    val keys = getKeys(obj)
//    console.log("GOT >" +  keys + "<")

    val total = keys.length as Int
    for (i in 0..total-1) {
//        console.log("\nPUT KEY >"+keys[i]+ "< isnull=>"+ jsIsNull(obj[keys[i]]) +"< typeof=>" + jsTypeOf(obj[keys[i]]) + "<" + "\n")
        map.put(keys[i], readAny(obj[keys[i]]))
    }
    return map
}

class ContractProxyJS(val tpack: Uni.TransactionPack): SmartContract {
    var signingKeys: Collection<PrivateKey> = emptySet()
    override suspend fun isSignedBy(address: KeyAddress): Boolean {
        val jsAddress = Unicrypto.KeyAddress(address.asBytes.toUint8Array())

        return tpack.contract.isSignedBy(SignedByOptions(address = jsAddress)).await()
    }

    override suspend fun addSignature(key: PrivateKey) {
        tpack.sign(key.universaKey).await()
    }

    override fun addSigningKey(key: PrivateKey) {
        signingKeys += key
    }

    override val state: Map<String, Any?>
        get() {
            return readObject(tpack.contract.capsule.contract.state.data).toMap()
        }

    override val definition: Map<String, Any?>
        get() {
            return readObject(tpack.contract.capsule.contract.definition.data).toMap()
        }

    override val transactional: Map<String, Any?>
        get() {
            val transactional = tpack.contract.capsule.contract.transactional
            if (jsIsNull(transactional)) return emptyMap()
//            console.log("\n????????????????\n")
//            console.log("\n>"+jsIsNull(transactional)+"<\n")
//            console.log("\n>"+ jsTypeOf(transactional) +"<\n")
//            console.log("\n????????????????\n")
            return readObject(transactional).toMap()
        }

    override fun <R> updateState(f: (MutableMap<String, Any?>) -> R): R {
        TODO("Not yet implemented")
    }

    override suspend fun pack(): ByteArray = tpack.pack().await().toByteArray()

    override var expiresAt: kotlinx.datetime.Instant
        get() = tpack.contract.capsule.contract.state.expiresAt.toKotlinInstant()
        set(value) {
            tpack.contract.capsule.contract.state.expiresAt = value.toJSDate()
        }

    override fun createRole(name: String, ids: Collection<AddressId>) {
        val role = Uni.RoleSimple(name, RoleSimpleOptions(addresses = ids.map { Unicrypto.KeyAddress(it.address.asBytes.toUint8Array()) }.toList()))

        addRole(role)
    }

    override suspend fun seal() {
        tpack.contract.packData() // also resets signatures
        signingKeys.forEach { key ->
            tpack.sign(key.universaKey).await()
        }
        tpack.pack().await()
    }

    fun addRole(role: Role) {
        if (role.name == "issuer") {
            tpack.contract.capsule.contract.definition.issuer = role
        } else if (role.name == "owner") {
            tpack.contract.capsule.contract.state.owner = role
        } else if (role.name == "creator") {
            tpack.contract.capsule.contract.state.creator = role
        } else {
            tpack.contract.capsule.contract.state.roles[role.name] = role
        }
    }

    override fun check(): String? {
        return null
    }

    override fun createRoleLink(existingRole: String, newRole: String) {
        addRole(Uni.RoleLink(newRole, existingRole))
    }
}