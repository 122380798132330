package api

import kotlinx.serialization.Serializable
import net.sergeych.parsec3.CommandHost
import net.sergeych.parsec3.WithAdapter
import net.sergeych.unikrypto.PublicKey

class ClientApi<T : WithAdapter> : CommandHost<T>() {

    val notification by command<ApiNotificatoin,Unit>()
}

val CLientApi = ClientApi<WithAdapter>()
