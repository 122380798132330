package controls

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun Clipcy(valueToShow: String, valueToCopy: String = valueToShow, tooltip: String? = null) {
    Span({
        classNames("copyable")
        attr("data-copyable", valueToCopy)
        attr("data-tooltiptitle", tooltip ?: "copied to the clipboard")
    }) {
        Text(valueToShow)
        LaunchedEffect(true) { js("setupCopyables()")}
    }
}
