package tools

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import net.sergeych.sprintf.sprintf

fun Instant.shortString(withSeconds: Boolean = false): String {
    val tf = if(withSeconds) "tT" else "tR"
    val tz = TimeZone.currentSystemDefault()
    val now = Clock.System.now().toLocalDateTime(tz)
    val x = this.toLocalDateTime(tz)
    return if (now.year == x.year) {
        if (now.month == x.month && now.dayOfMonth == x.dayOfMonth)
            "%1!$tf".sprintf(this)
        else
            "%td.%1!tm %1!$tf".sprintf(this)
    } else
        "%td.%1!tm.%1!ty %1!$tf".sprintf(this)
}

fun String.trimMiddle(size: Int): String {
    if (this.length <= size) return this
    var l0 = (size - 1) / 2
    val l1 = l0
    if (l0 + l1 + 1 < size) l0++
    if (l0 + l1 + 1 != size) throw RuntimeException("big in trimMiddle: $size $l0 $l1")
    return substring(0, l0) + '…' + substring(length - l1)
}
