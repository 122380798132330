import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import controls.Di
import controls.Dialog
import controls.TextField
import kotlinx.browser.localStorage
import kotlinx.browser.window
import net.sergeych.mp_logger.Log
import net.sergeych.mp_tools.decodeBase64
import net.sergeych.mp_tools.encodeToBase64
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.superlogin.InitSuperlogin
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.opacity
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.get
import org.w3c.dom.set

val isLocalTest by lazy {
    val x = window.location.host
    x.startsWith("localhost") || x.startsWith("127.0.0.1") || x.startsWith("0.0.0.0")
    false
}

val client = IndigoClient(
//    if (isLocalTest) "ws://localhost:8116/api/p3" else "wss://platina.sergeych.net/api/p3",
    if (isLocalTest) "ws://localhost:8116/api/p3" else "wss://indigo.8-rays.dev/api/p3",
    localStorage.getItem("__slstate__")?.decodeBase64()
) {
    localStorage.setItem("__slstate__", it.encodeToBase64())
}

const val betaCode = "solar cat"
const val betaLabel = "%%&@"

fun main() {
    Log.connectConsole(Log.Level.DEBUG)
    console.log("запускаем...")
    globalLaunch { InitSuperlogin() }
    renderComposable(rootElementId = "root") {
        var betaCodeOk by remember { mutableStateOf<Boolean>(localStorage.get(betaLabel) == betaCode) }

        if (betaCodeOk) {
            Di("position-fixed bottom-0 end-0 p-1") {
                Text("r${Settings.revision}")
            }
            Di("position-fixed bottom-0 start-0 mb-2 ms-1") {
                Img("logo-colored.svg") {
                    style {
                        height(7.em)
                        opacity(0.4)
                        property("z-index", -100)
                        cursor("pointer")
                    }
                    onClick {
                        window.open("https://8-rays.dev")
                    }
                }
            }
            Router.userContent()
        } else {
            Dialog {
                heading("Please provide βeta access code")
                staticBackdrop()
                body {
                    var code by remember { mutableStateOf("") }
                    TextField(
                        code,
                        "enter code here"
                    ) {
                        code = it
                        if (code == betaCode) {
                            window.localStorage.set(betaLabel, code)
                            betaCodeOk = true
                            close()
                        }
                    }
                }
            }
        }
    }
}

