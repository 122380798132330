
package api

import kotlinx.serialization.Serializable
import net.sergeych.unikrypto.PublicKey

@Serializable
class SetPublicKeyPayload(
    val publicKey: PublicKey,
    val encryptedPrivateKey: ByteArray,
)

