package controls

import androidx.compose.runtime.*
import api.ApiUser
import client
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.dom.*
import tools.rememberDebouncer
import views.unaryPlus
import kotlin.time.Duration.Companion.milliseconds

@Composable
fun SelectUser(user: ApiUser?, onChange: (ApiUser?) -> Unit) {
    val fragment = remember { mutableStateOf<String?>(null) }
    var users by remember { mutableStateOf<List<ApiUser>>(listOf()) }
    val drSearch = rememberDebouncer(500.milliseconds) {
        users = fragment.value?.let { client.searchUsers(it) } ?: listOf()
    }

    Di("shadow-sm p-3 bg-light rounded") {
        Di("d-inline-block w-100") {
            SearchField(fragment, "name search",
                onCancel = { onChange(null) }
            ) {
                drSearch.schedule()
            }
        }
        if (users.isEmpty()) {
            if (fragment.value != null) {
                Small { +"nothing found" }
                Br()
            }
        } else {
            ResponsiveTable {
                Tbody {
                    for (cy in users) {
                        Tr({
                            style { cursor("pointer") }
                            onClick {
                                onChange(cy)
                                it.preventDefault()
                            }
                        }) {
                            Td { Text(cy.id.toString()) }
                            Td { Text(cy.name) }
                        }
                    }
                }
            }
        }
    }
}