import api.*
import com.ionspin.kotlin.bignum.decimal.BigDecimal
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.yield
import kotlinx.serialization.Serializable
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.boss_serialization_mp.decodeBoss
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.debug
import net.sergeych.mp_tools.encodeToBase64
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.mptools.CachedExpression
import net.sergeych.mptools.toDump
import net.sergeych.parsec3.CommandDescriptor
import net.sergeych.parsec3.Parsec3WSClient
import net.sergeych.parsec3.WithAdapter
import net.sergeych.superlogin.PasswordDerivationParams
import net.sergeych.superlogin.client.ClientState
import net.sergeych.superlogin.client.LoginState
import net.sergeych.superlogin.client.Registration
import net.sergeych.superlogin.client.SuperloginClient
import net.sergeych.unikrypto.*
import platform.unpackSmartContract

class IndigoClient(
    url: String,
    packedStateData: ByteArray? = null,
    private val savePackedStateData: (ByteArray) -> Unit = {},
) : LogTag("IDGCL") {

    class Session : WithAdapter() {

    }

    @Serializable
    internal data class SavedState(
        val loginToken: ByteArray,
        val user: ApiUserDetails,
        val dataKey: SymmetricKey,
    ) {
        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as SavedState

            if (!loginToken.contentEquals(other.loginToken)) return false
            if (user != other.user) return false
            if (dataKey != other.dataKey) return false

            return true
        }

        override fun hashCode(): Int {
            var result = loginToken.contentHashCode()
            result = 31 * result + user.hashCode()
            result = 31 * result + dataKey.hashCode()
            return result
        }
    }

    val api = IndigoApi

    private var savedState: SavedState? = try {
        packedStateData?.decodeBoss<SavedState>()
    } catch (t: Throwable) {
        println("не удалось раскодировать состояние")
        null
    }
        set(value) {
            field = value
            savePackedStateData(BossEncoder.encode(value))
            _userFlow.value = value?.user
            debug { "saved state st $value" }
        }

    val _userFlow = MutableStateFlow(savedState?.user)

    val userFlow: StateFlow<ApiUserDetails?> = _userFlow.asStateFlow()

    val notifications = MutableSharedFlow<ApiNotificatoin>(extraBufferCapacity = 10)

    val currentUser: ApiUserDetails? get() = savedState?.user

    private val slc =
        SuperloginClient<ApiUserDetails, Session>(
            Parsec3WSClient(url, ClientApi()) {
                addErrors(cloudocsExceptions)
                newSession { Session() }
                on(api.notification) {
                    globalLaunch {
                        println("!notification> : $it")
                        notifications.emit(it)
                    }
                }
            },
            savedData = savedState?.let { ss ->
                ClientState(ss.user.loginName, ss.loginToken, ss.user, ss.dataKey)
            },
        )

    val isLoggedIn: Boolean get() = currentUser != null

    fun requireNotLoggedIn() {
        if (isLoggedIn) throw NotAuthenticatedException("недопустимая операция: пользователь авторизован")
    }

    fun requireLoggedIn() {
        if (!isLoggedIn) throw NotAuthenticatedException("недопустимая операция: пользователь не авторизован")
    }

    val _mainPublicKey = CachedExpression<PublicKey>()
    suspend fun mainPublicKey(): PublicKey {
        return _mainPublicKey.get {
            call(api.getMyPublicKey)
        }
    }

    private var _mainPrivateKey: PrivateKey? = null

    private val _ring = CachedExpression<Keyring>()

    suspend fun mainRing(): Keyring = _ring.get {
        Keyring(dataKey, mainPrivateKey())
    }

    /**
     * Retrieve main data key. Main data key is only available in logged in state, so
     * you might check [isLoggedIn]
     */
    val dataKey: SymmetricKey
        get() = slc.dataKey ?: throw NotAuthenticatedException(
            "ключ данных недоступен, требуется аутентификация"
        )

    val dataKeyOrNull by slc::dataKey

    suspend fun searchUsers(pattern: String): List<ApiUser> =
        try {
            slc.call(api.findUser, pattern).also {
                println("Seacrh user $pattern: $it")
            }
        } catch (x: Exception) {
            x.printStackTrace()
            listOf()
        }

    suspend fun userByWallet(walletId: Long) = slc.call(api.findUserByWallet,walletId)

    suspend fun registerInvoice(_invoice: ApiInvoice, progress: (() -> Unit)? = null) {
        requireLoggedIn()
        val key = mainPrivateKey()
        val invoice = _invoice.copy(vendor = currentUser!!.asUser)
        val packed = call(api.createInvoiceContract, invoice)

        println("packed:\n${packed.toDump()}")

        val contract = ApiContract.unpack<ApiInvoice>(packed)

        if (contract.optPayload != invoice)
            throw InternalError("сервер вернул измененный инвойс")

        println("calling inv1, it is ok")
        progress?.invoke()
        println("called inv1")

        println("contract:\n${packed.encodeToBase64()}")
        println("key:\n${key.packed.encodeToBase64()}")

        contract.addSignature(key)
        println("signed con")
        debug { "contract is signed, rgistring" }

        call(api.registerInvoice, contract.pack())

        debug { "contract registered" }
    }

    suspend fun requestImport(user: ApiUser, amount: BigDecimal, description: String): ApiOperation {
        return call(api.requestImport, ApiImportRequest(user, amount, description))
    }

    /**
     * Get the main private key, a random key created at the registration time.
     * It is cached in the client
     */
    suspend fun mainPrivateKey(): PrivateKey {
        // could be cached
        _mainPrivateKey?.let { return it }
        // otherwise we need data key that might be already known:
        var k = dataKey
        return Container.decrypt<PrivateKey>(call(api.getMyMainKey), k)
            ?.also { _mainPrivateKey = it }
            ?: throw InternalError("ошибка расщифровки главного ключа")
    }

    suspend fun transfer(
        beneficiary: ApiUser, amount: BigDecimal, description: String,
        invoice: ApiInvoice? = null,
    ): ApiOperation =
        coroutineScope {
            // Get contract and key in parallel: could be faster
            val aKey = async { mainPrivateKey() }
            val aContract =
                async { call(api.prepareTransfer, ApiTransferRequest(beneficiary, amount, description, invoice)) }
            val contract = unpackSmartContract(aContract.await())
            debug { "got transfer contract and the key" }
            val ownerKey: PrivateKey = aKey.await()
            debug { "signing with ${ownerKey.id::class.simpleName}"}
            debug { "signing with ${ownerKey.id.asString}"}
            contract.addSignature(aKey.await())
            debug { "sending signed contract" }
            call(api.transferFunds, contract.pack())
        }

    init {
//        println("packed saved state:\n${packedStateData?.toDump()}")
//        println("decoded saved state:\n${packedStateData?.decodeBoss<SavedState>()}")
//        println("Initial loaded saved state: $savedState")
//        println("Initial loaded saved user: ${savedState?.user}")
        globalLaunch {
            slc.state.collect {
                when (it) {
                    is LoginState.LoggedIn<*> -> {
                        @Suppress("UNCHECKED_CAST")
                        val ld = it.loginData as ClientState<ApiUserDetails>
                        debug { "service state is now logged in as ${ld.data?.loginName}" }
                        savedState = SavedState(
                            ld.loginToken!!, ld.data!!,
                            ld.dataKey
                        )
                    }

                    LoginState.LoggedOut -> {
                        debug { "service state changed: logged out" }
                        savedState = null
                    }
                }
            }
        }
    }

    /**
     * Simplified registration call, usually for testing purposes. It is advised
     * to use version that takes [ApiUserDetails] in productino code
     */
    suspend fun register(
        loginName: String,
        password: String,
        name: String = loginName,
        pbkdfRounds: Int = 50000,
        loginKeyStrength: Int = 2048,
        mainKeyStrength: Int = 2048,
    ): ApiUserDetails =
        register(ApiUserDetails(loginName, name), password, pbkdfRounds, loginKeyStrength, mainKeyStrength)

    /**
     * Register new user. If is a time-consuming suspend function.
     */
    suspend fun register(
        newUser: ApiUserDetails,
        password: String, pbkdfRounds: Int = 50000,
        loginKeyStrength: Int = 2048,
        mainKeyStrength: Int = 2048,
    ): ApiUserDetails {
        requireNotLoggedIn()
        val loginName =
            newUser.loginName
        return coroutineScope {
            val publicKeyDeferred = async { AsymmetricKeys.generate(mainKeyStrength) }
            val r = slc.register(loginName, password, newUser, loginKeyStrength, pbkdfRounds)
            when (r) {
                is Registration.Result.Success -> {
                    debug { "setting up main key" }
                    val key = publicKeyDeferred.await()
                    debug { "we got main key" }
                    val packedSr = SignedRecord.pack(
                        key, SetPublicKeyPayload(
                            key.publicKey,
                            Container.encrypt(key, slc.dataKey!!)
                        )
                    )
                    debug { "trying to register main key" }
                    val mydetails = r.data<ApiUserDetails>()
                        ?: throw InternalError("сервис не предоставил данные пользователя")
                    call(api.setMyPublicKey, packedSr)
                    debug { "public key is created" }
                    savedState = SavedState(
                        r.loginToken,
                        mydetails,
                        r.dataKey
                    )
                    mydetails
                }

                Registration.Result.InvalidLogin -> throw LoginInUseException()
                is Registration.Result.NetworkFailure -> throw LoginFailed()
            }
        }
    }

    /**
     * Tries to log in (must be logged out). On succcess, returns true and set [userFlow]
     * to the logged-in user details instance.
     *
     * @return true on successful login
     */
    suspend fun login(loginName: String, password: String): Boolean {
        requireNotLoggedIn()
        return slc.loginByPassword(loginName, password)?.also {
            if (it.loginToken == null)
                throw InternalError("сервис не предоставил токен после логина")
            if (it.data == null)
                throw InternalError("сервис не предоставил данные пользователя после логина")
            savedState = SavedState(it.loginToken!!, it.data!!, it.dataKey)
        } != null
    }

    /**
     * Perform log out, If not logged in, does nothing
     */
    suspend fun logout() {
        if (isLoggedIn) {
            slc.logout()
            // We want state to be collected before leaving:
            yield()
        }
    }

    suspend fun resetPasswordAndLogin(
        secret: String, newPassword: String, pbkdfRounds: Int = 50000,
        loginKeyStrength: Int = 2048,
    ): Boolean {
        requireNotLoggedIn()
        return slc.resetPasswordAndSignIn(
            secret,
            newPassword,
            PasswordDerivationParams(pbkdfRounds),
            loginKeyStrength
        ).also { yield() } != null
    }

    suspend fun changePassword(
        currentPassword: String, newPassword: String,
        pbkdfRounds: Int = 50000,
        loginKeyStrength: Int = 2048,
    ): Boolean {
        requireLoggedIn()
        return slc.changePassword(
            currentPassword,
            newPassword,
            PasswordDerivationParams(pbkdfRounds),
            loginKeyStrength
        )
    }

    suspend fun <A, R> call(cmd: CommandDescriptor<A, R>, args: A): R = slc.call(cmd, args)

    suspend fun <R> call(cmd: CommandDescriptor<Unit, R>): R = slc.call(cmd)

    suspend fun wallets(): ApiMyWallets {
        return call(api.getMyWallets)
    }

    suspend fun balance(): BigDecimal {
        // todo: implement
        return wallets().checking.balance
    }

    fun operations(): Flow<ApiOperation> = flow {
        var id = Long.MAX_VALUE
        do {
            val ops = call(api.listOperations, ApiRequestOperations(id, 20, 0))
            for (op in ops) {
                emit(op)
                id = op.id
            }
        } while (ops.isNotEmpty())
    }

    suspend fun refreshOperation(operation: ApiOperation): ApiOperation =
        call(api.getOperation, operation.id)
    suspend fun getOperation(operationId: Long): ApiOperation =
        call(api.getOperation, operationId)

    suspend fun cancelAwaitingOperations() {
        call(api.dropAwaitingTransfers)
    }

    fun operationLog(op: ApiOperation): Flow<ApiLog> = flow {
        var beforeId = Long.MAX_VALUE
        do {
            val records = call(api.getOperationLog,
                GetOperationLogArgs(op.id, beforeId))
            for( r in records ) {
                emit(r)
                beforeId = r.id
            }
        } while(records.isNotEmpty())
    }

    @Suppress("UNUSED_PARAMETER")
    fun invoices(
        onlyIcoming: Boolean = false,
        onlyOutgoing: Boolean = false,
        filterPaid: Boolean? = null,
        onlyNotPaid: Boolean = false,
    ): Flow<ApiInvoice> = flow {
        var lastGuid: String? = null
        do {
            val invoices = call(
                api.listInvoices, ApiRequestInvoices(
                    onlyIcoming, !onlyIcoming, filterPaid, lastGuid
                )
            )
            for (i in invoices) {
                emit(i)
                lastGuid = i.guid
            }
        } while (invoices.isNotEmpty())
    }

    suspend fun getInvoice(guid: String) = call(api.getInvoice, guid)

    suspend fun getPublicKey(user: ApiUser): PublicKey {
        println("calling getpk for ${user.id}")
        return call(api.getPublicKey, user.id)
    }

    suspend fun requestCreditPayment(invoice: ApiInvoice) {
        call(api.requestCreditPayment, invoice.guid)
    }

    suspend fun payInvoice(invoice: ApiInvoice): ApiOperation =
        transfer(
            invoice.vendor,
            invoice.total,
            "оплата счета ${invoice.guid}",
            invoice
        )

    /**
     * Checks login name
     * @return value or null on communications error (meanining state is unknown)
     */
    suspend fun isLoginNameAvailable(name: String): Boolean? {
        return try {
            call(api.isLoginNameAvailable, name)
        } catch (x: Throwable) {
            x.printStackTrace()
            return null
        }
    }

}
