package views

import Router
import androidx.compose.runtime.*
import api.ApiNotificatoin
import api.ApiOperation
import api.ApiUserDetails
import api.ApiWallet
import client
import kotlinx.coroutines.delay
import com.ionspin.kotlin.bignum.decimal.BigDecimal
import com.ionspin.kotlin.bignum.decimal.RoundingMode
import controls.*
import controls.Col
import kotlinx.browser.window
import kotlinx.coroutines.flow.firstOrNull
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.debug
import net.sergeych.mp_logger.info
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.unikrypto.withUnicrypto
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLButtonElement
import tools.csplit
import kotlin.time.Duration.Companion.seconds

@Composable
fun UserHome(user: ApiUserDetails) {
    var mainBalance by remember { mutableStateOf<BigDecimal?>(null) }
    var creditBalance by remember { mutableStateOf<BigDecimal?>(null) }
    var opInProgress by remember { mutableStateOf<Boolean?>(null) }
    var address by remember { mutableStateOf(".....") }
    val scope = rememberCoroutineScope()

    @Composable
    fun UserBlock(addStyle: String = "") {
        Di("col shadow-sm p-3 bg-body rounded " + addStyle) {
            Di("w-100") {
                Di("text-center") {
                    Bn({
                        classes("btn")
                        onClick {
                            globalLaunch { client.logout() }
                        }
                    }) {
                        Span({
                            style {
                                fontSize(0.7.em)
                                whiteSpace("nowrap")
                            }
                        }) {
                            Text("${user.id}: ${user.name}")
                        }
                        Br {}
                        Icon.BoxArrowLeft.render()
                        Text("Exit")
                    }
                }
            }
        }
    }

    LaunchedEffect(true) {
        println("LaunchedEffect1")
        scope.launch {
            opInProgress = client.operations().firstOrNull { it.state == ApiOperation.State.AwaitingSignature } != null
        }
        scope.launch {
            client.notifications.collect {
                if (it is ApiNotificatoin.BalanceChanged) {
                    when (it.wallet.type) {
                        ApiWallet.Type.Credit -> creditBalance = it.wallet.balance
                        ApiWallet.Type.Checking -> mainBalance = it.wallet.balance
                    }
                }
            }
        }
        scope.launch {
            Toaster.launchCatching {
                withUnicrypto {
                    val k1 = client.mainPublicKey().id.asString
                    val k2 = client.mainPrivateKey().id.asString
                    address = if (k1 == k2) k1 else "!! $k1 / $k2"
                }
            }
        }
        try {
            val ww = client.wallets()
            mainBalance = ww.checking.balance
            creditBalance = ww.credit.balance

        } catch (t: Throwable) {
            Toaster.error(t)
            mainBalance = null
            creditBalance = null
        }
    }

    when (opInProgress) {
        true -> {
            Di("alert alert-warning") {
                Text("Unfinished operation found. Please wait")
                InlineSpinner()
            }
            startCleanup()
        }
        null -> {
//            Di("alert alert-info") {
//                Text("checking pending operations...")
//                InlineSpinner()
//            }
        }
        false -> {}

    }
    Row({ classes("mt-4 mb-3".csplit) }) {
        Col("auto") {
            Di("col-auto align-content-center text-center") {
                Img(src = "/gold-logo1.png") {
                    style {
                        height(4.em)
                    }
                }
                Br {}
                B { Text("INDIGO") }
            }
        }
        BalanceSlot("Balance:", "mainBalance", mainBalance)
        BalanceSlot("Credit:", "creditBalance", creditBalance)

        UserBlock("d-none d-sm-block")
    }

    Di("row g-2 mb-4 mt-5 row-cols-auto") {
        Bt("Create invoice", "btn-light", "/new_invoice", prerender = {
            Icon.ReceiptCutoff.render()
            Br()
        })
        Bt("Incoming invoices", "btn-light", "/invoices?incoming=1", prerender = {
            Icon.Person.render()
            Icon.ArrowLeft.render()
            Icon.Receipt.render()
            Br()
        })
        Bt("Outgoing invoices", "btn-light", "/invoices?outgoing=1", prerender = {
            Icon.Person.render()
            Icon.ArrowRight.render()
            Icon.Receipt.render()
            Br()
        })
        Bt("Create transfer", "btn-light text-danger", "/new_transfer", prerender = {
            Icon.CashStack.render()
            Icon.ArrowRight.render()
            Icon.Person.render()
            Br()
        })
        Bt("History", "btn-light", "/operations", prerender = {
            Icon.ClockHistory.render()
            Br()
        })
        Bt(
            "Exchange fiat", "btn-light text-danger", "/import",
            isDisabled = client.currentUser?.canPerformImport != true,
            prerender = {
                Icon.CurrencyExchange.render()
                Br()
            }
        )
    }
    UserBlock("d-sm-none")
}

@Composable
fun InlineSpinner() {
    Di("d-inline") { Di("spinner-border spinner-border-sm ms-1") {} }
}

@Composable
fun BalanceSlot(title: String, elemId: String, balance: BigDecimal?) {
    Col {
        Di("shadow-sm p-3 bg-body rounded") {
            Text(title)
            Di("mb-2 fw-bold") {
                if (balance == null)
                    Di("spinner-border spinner-border-sm mv-3") {}
                else
                    Div({
                        id(elemId)
                        classes("fs-4")
                    }) {
                        val x = balance.roundToDigitPositionAfterDecimalPoint(2, RoundingMode.ROUND_HALF_CEILING)
                        val notExact = x != balance
                        WithTooltip(
                            if (notExact) "Exact value: ${balance.toPlainString()}"
                            else "This is the exact value",
                            { classNames("position-relative") }
                        ) {
                            Text(x.toPlainString())
                            if (notExact)
                                Di("fd-6 fs-5 fw-normal d-inline position-absolute text-primary",
                                    {
                                        style {
                                            top(-0.3.em)
                                        }
                                    }) { Text("*") }
                        }
                    }
            }
        }
    }
}


@Composable
fun Bt(
    text: String, classes: String, link: String = "", isDisabled: Boolean = false,
    prerender: (@Composable ElementScope<HTMLButtonElement>.() -> Unit)? = null,
    click: (() -> Unit)? = null,
) {
    Col("col-xs-6 col-4") {
        Bn({
            classNames("btn w-100")
            classNames(classes)
            if (isDisabled) disabled()
            onClick { click?.invoke() ?: Router.push(link) }
        }) {
            prerender?.invoke(this)
            Text(text)
        }
    }
}

fun startCleanup() {
    val log = LogTag("CLUP")
    globalLaunch {
        while (true) {
            val op = client.operations().firstOrNull { it.state == ApiOperation.State.AwaitingSignature }
            if( op == null ) {
                log.debug { "nothing to do"}
                break
            } else if( Clock.System.now() - op.createdAt < 15.seconds  ) {
                log.debug { "hanged operation ${op.id} is too new, waiting" }
                delay(500)
            }
            else {
                log.info { "stalled transfer to be cleaned up: ${op.id}" }
                client.cancelAwaitingOperations()
                Toaster.info("Pending operations have been cancelled")
                window.location.reload()
                break
            }
        }
    }
}

