package views

import androidx.compose.runtime.*
import api.ApiUserDetails
import api.LoginInUseException
import client
import controls.*
import kotlinx.browser.document
import kotlinx.coroutines.launch
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.unikrypto.Passwords
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement

@Composable
fun Registration(additionalContent: ContentBuilder<HTMLDivElement>) {
    var loginName by remember { mutableStateOf("testUser") }
    var publicName by remember { mutableStateOf("test user") }
    var loginMessage by remember { mutableStateOf<String?>(null) }
    var loginOk by remember { mutableStateOf<Boolean?>(null) }
    var password1 by remember { mutableStateOf("12345qwert") }
    var password1Message by remember { mutableStateOf("") }
    var password1Ok by remember { mutableStateOf<Boolean?>(null) }
    var password2 by remember { mutableStateOf("12345qwert") }
    var password2Ok by remember { mutableStateOf<Boolean?>(null) }
    var inProgress by remember { mutableStateOf(false) }

    val scope = rememberCoroutineScope()

    fun checkLogin() {
        when {
            loginName.trim() != loginName -> {
                loginOk = false
                loginMessage = "не должно содержать пробелов в начале и конце"
            }

            loginName.length == 0 -> {
                loginOk = false
                loginMessage = "требуемое поле"

            }

            loginName.length < 3 -> {
                loginOk = false
                loginMessage = "слишком короткое (${loginName.length}"

            }

            loginName[0] == '.' -> {
                loginOk = false
                loginMessage = "не должно начинаться с точки"
            }

            else -> {
                loginOk = null
                loginMessage = null
                scope.launch {
                    println("Start check login")
                    loginOk = client.isLoginNameAvailable(loginName)
                    println("end check login: $loginOk")
                    loginMessage = if (loginOk == false) "недоступное имя" else null
                }
            }
        }
    }

    fun checkPasswords() {
        val strength = Passwords.estimateBitStrength(password1)
        when {
            password1.length == 0 -> {
                password1Ok = false
                password1Message = "требуемое поле"
            }

            strength < 32 -> {
                password1Ok = false
                password1Message = "слишком слабый пароль (~$strength бит)"
            }

            else -> {
                password1Ok = true
                password1Message = "оценочная стойкость $strength бит"
            }
        }
        password2Ok = password1Ok == true && password1 == password2
    }

    checkLogin()
    checkPasswords()

    CenteredNarrowForm {
        if (inProgress) {
            WaitPanel("registration in progress", "also generating private keys")
        } else {
            H3 { Text("New user registration:") }
            val loginId = TextField(
                loginName,
                "Login name (invisible to others)",
                isValid = loginOk,
                message = loginMessage
            ) {
                loginName = it
                checkLogin()
            }
            TextField(
                publicName,
                "Your name visible to public",
                isValid = !publicName.isBlank(),
                message = if (publicName.isBlank()) "required field" else null
            ) {
                publicName = it
            }
            TextField(
                password1,
                "Password",
                type = InputType.Password,
                isValid = password1Ok,
                message = password1Message
            ) {
                password1 = it
                checkPasswords()
            }
            TextField(
                password2,
                "Repeat the password",
                type = InputType.Password,
                isValid = password2Ok,
                message = if (password2Ok == false && password1.isNotBlank()) "не совпадают" else null
            ) {
                password2 = it
                checkPasswords()
            }
            Bn({
                classNames("btn-primary me-2")
                if (inProgress || publicName.isBlank() || loginOk == false || password1Ok == false || password2Ok != true)
                    disabled()
                onClick {
                    inProgress = true
                    globalLaunch {
                        // local scope will be cancelled as soo as we get online, e.g.
                        // our code will be cancelled before registerig privae keys!
                        try {
                            client.register(
                                ApiUserDetails(
                                    loginName = loginName, name = publicName
                                ), password1
                            )
                            Toaster.info("Registration successful")
                        } catch (x: LoginInUseException) {
                            loginOk = false
                            loginMessage = "Name is not available"
                        } catch (t: Throwable) {
                            Toaster.error("We apologise: there is a registration error, please try again later. (${t.message})")
                        }
                        inProgress = false
                    }
                }
            }) {
                Text("Registration")
            }
            additionalContent()
            LaunchedEffect("registratinoFocus") {
                (document.getElementById(loginId) as? HTMLInputElement)?.focus()
            }
        }
    }
}