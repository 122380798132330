package views

import LoggedIn
import Router
import androidx.compose.runtime.*
import api.*
import backHeader
import client
import controls.*
import kotlinx.coroutines.await
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.unikrypto.PublicKey
import net.sergeych.unikrypto.Unicrypto
import org.jetbrains.compose.web.attributes.colspan
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import tools.trimMiddle

@Composable
fun ShowInvoice() {
    var invoice by remember { mutableStateOf<ApiFullInvoice?>(null) }
    LoggedIn { user ->
        val guid = Router.param(0) ?: ""
        LaunchedEffect(guid) {
            if (guid == "") {
                Router.back()
                Toaster.error("invalid invoice id")
            }
            try {
                invoice = client.getInvoice(guid)
            } catch (x: NotFoundException) {
                Router.back()
                Toaster.error("invoice not found")
            } catch (t: Throwable) {
                Router.back()
                Toaster.error(t)
            }
        }
        invoice?.let { fi ->
            RC {
                val i = fi.invoice
                backHeader("Invoice ${i.guid}")
                Table({ classNames("mt-3") }) {
                    Tr {
                        Td {
                            B { Text("Vendor: ") }
                        }
                        Td({ classNames("ps-3") }) {
                            UserBlock(i.vendor)
                        }
                    }
                    Tr {
                        Td {
                            B { Text("Customer: ") }
                        }
                        Td({ classNames("ps-3") }) {
                            UserBlock(i.customer)
                        }

                    }
                }
                Di("mt-3 mb-3") {
                    B { Text("State: ") }
                    Di("d-inline fw-bold", {
                        classNames(
                            when (i.state) {
                                ApiInvoice.State.Draft -> "text-dark"
                                ApiInvoice.State.AwaitPayment -> "text-info"
                                ApiInvoice.State.Paid -> "text-success"
                                ApiInvoice.State.Rejected -> "text-warning"
                            }
                        )
                    }) {
                        Text(i.state.text)
                    }
                }
                PlainTable {
                    Thead {
                        Tr {
                            Th { Text("name") }
                            Th { Text("amount") }
                            Th { Text("price") }
                            Th { Text("subtotal") }
                        }
                    }
                    Tbody {
                        for (p in i.positions) {
                            Tr {
                                Td { Text(p.name) }
                                Td { Text(p.quantity.toPlainString()) }
                                Td { Text(p.unitPrice.toPlainString()) }
                                Td { Text(p.cost.toPlainString()) }
                            }
                        }
                        Tr {
                            Td({ colspan(3) }) {
                                B { Text("TOTAL") }
                            }
                            Td { B { Text(i.total.toPlainString()) } }
                        }
                    }
                }
                ShowSignature(fi) {
                    Actions(user, i)
                }
            }

            contractDetails(fi.packedContract)

        } ?: WaitPanel("loading invoice $guid")

    }
}

@Composable
internal fun Actions(user: ApiUserDetails, invoice: ApiInvoice) {
    var wallet by remember { mutableStateOf<ApiWallet?>(null) }
    var busy by remember { mutableStateOf(false) }

    if (invoice.state == ApiInvoice.State.AwaitPayment && user.id == invoice.customer.id) {
        LaunchedEffect(user) {
            wallet = client.wallets().checking
        }

        wallet?.let { w ->
            if (busy) {
                WaitMessage("requesting the credit")
            } else {
                if (w.balance >= invoice.total) {
                    Bn({
                        classNames("btn-primary me-2")
                        onClick {
                            busy = true
                            globalLaunch {
                                try {
                                    client.payInvoice(invoice)
                                    Router.push("/")
                                    Toaster.info("invoice is paid successfully")
                                } catch (t: Throwable) {
                                    t.printStackTrace()
                                    Toaster.error(t)
                                } finally {
                                    busy = false
                                }
                            }
                        }

                    }) {
                        Text("Pay")
                    }
                }
                Bn({
                    classNames("btn-warning me-2")
                    onClick {
                        busy = true
                        globalLaunch {
                            try {
                                client.requestCreditPayment(invoice)
                                Router.push("/")
                                Toaster.info("Submitted the credit payment request")
                            } catch (t: Throwable) {
                                t.printStackTrace()
                                Toaster.error(t)
                            } finally {
                                busy = false
                            }
                        }
                    }
                }) {
                    Text("Request credit payment")
                }
            }
        }
            ?: Div({ classes("spinner-border", "mv-3") })
    }
}


@Composable
fun ShowSignature(fi: ApiFullInvoice, content: ContentBuilder<HTMLDivElement>) {
    var signed by remember { mutableStateOf<Boolean?>(null) }
    var vendorKey by remember { mutableStateOf<PublicKey?>(null) }
    LaunchedEffect(fi.invoice) {
        try {
            Unicrypto.unicryptoReady.await()
            client.getPublicKey(fi.invoice.vendor).let {
                signed = fi.isSignedBy(it)
                vendorKey = it
            }
        } catch (t: Throwable) {
            t.printStackTrace()
            signed = false
            Toaster.error(t)
        }
    }
    when (signed) {
        null -> {
            Di("alert alert-primary") {
                Di("spinner-border spinner-border-sm d-inline-block me-2") {}
                Text("Checking signatures, smart contract size is: ${fi.packedContract.size}")
            }
        }

        true -> {
            Di("alert alert-success") {
                Text("Signed by the vendor, signature is trusted.")
                vendorKey?.let {
                    Text(" (ID=${fi.invoice.vendor.id}: ")
                    Clipcy(it.id.asString.trimMiddle(17))
                    Text(").")
                }
            }
            Div() { content() }
        }

        false -> {
            Di("alert alert-danger") {
                Text("Missing a valid signature of the vendor!")
            }

        }
    }
}
