package controls

import Router
import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLButtonElement

enum class BnType(val names: String) {
    PRIMARY("primary"),
    SECONDARY("secondary"),
    DANGER("danger"),
    WARNING("warning"),
    SUCCESS("success"),
    INFO("info")
}


fun AttrsScope<HTMLButtonElement>.primary(vararg clsNames: String) {
    classes(clsNames.toList() + "btn-primary")
}

fun AttrsScope<HTMLButtonElement>.secondary(vararg clsNames: String) {
    classes(clsNames.toList() + "btn-secondary")
}
fun AttrsScope<HTMLButtonElement>.light(vararg clsNames: String) {
    classes(clsNames.toList() + "btn-light")
}
fun AttrsScope<HTMLButtonElement>.dark(vararg clsNames: String) {
    classes(clsNames.toList() + "btn-dark")
}

fun AttrsScope<HTMLButtonElement>.info(vararg clsNames: String) {
    classes(clsNames.toList() + "btn-info")
}

fun AttrsScope<HTMLButtonElement>.success(vararg clsNames: String) {
    classes(clsNames.toList() + "btn-success")
}

@Composable
fun Bn(attrBuilderContext: AttrBuilderContext<HTMLButtonElement>? = null, builder: ContentBuilder<HTMLButtonElement>) {
    Button({
        classes("btn")
        attrBuilderContext?.invoke(this)
    }) {
        builder()
    }
}

@Composable
fun Blink(
    url: String,
    attrs: AttrBuilderContext<HTMLButtonElement>? = null,
    text: String? = null,
    builder: ContentBuilder<HTMLButtonElement>? = null,
) {
    Bn({
        onClick { Router.push(url) }
        attrs?.invoke(this)
    }) {
        text?.let { Text(it) }
        builder?.invoke(this)
    }
}
