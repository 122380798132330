package views

import LoggedIn
import Router
import androidx.compose.runtime.*
import api.ApiLog
import api.ApiOperation
import api.NotFoundException
import backHeader
import client
import controls.*
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.attributes.type
import org.jetbrains.compose.web.css.whiteSpace
import org.jetbrains.compose.web.dom.*
import tools.shortString

@Composable
fun Operation() {
    LoggedIn {
        var operation: ApiOperation? by remember { mutableStateOf(null) }
        val log = remember { mutableStateListOf<ApiLog>() }
        var contract by remember { mutableStateOf<ByteArray?>(null) }
        var contractChecked by remember { mutableStateOf(false) }

        operation?.let { op ->
            backHeader("Operation #${op.id}: ${op.type}")
            propTable {
                prop("created") { Text(op.createdAt.shortString()) }
                prop("type") { Text(op.type.toString()) }
                prop("amount") { Text(op.amount.toPlainString()) }
                prop("state") { Text(op.state.toString()) }
                prop("notes") { op.comment?.let { Text(it) } }
            }
            Hr {}
            Di("accordion") {
//                Di("accordion-item") {
                if (!contractChecked || contract != null) {
                    Di("accordion-item") {
                        H2({ classes("accordion-header") }) {
                            Button({
                                type(ButtonType.Button)
                                classNames("accordion-button collapsed")
                                attr("data-bs-toggle", "collapse")
                                attr("data-bs-target", "#contractDetails")
                            }) {
                                Text("Operation contract details")
                            }
                        }
                        Di("accordion-collapse collapse", {
                            id("contractDetails")
                        }) {
                            Di("accordion-body") {
                                if (contractChecked) {
                                    contract?.let { contractDetails(it, false) }
                                } else {
                                    Di("alert alert-light") { Text("retrieving contract details...") }
                                }
                            }
                        }
                    }
                } else {
                    Di("alert alert-secondary") { Text("operation has no contract") }
                }
                if (log.isNotEmpty()) {
                    Di("accordion-item") {
                        H2({ classes("accordion-header") }) {
                            Button({
                                type(ButtonType.Button)
                                classNames("accordion-button collapsed")
                                attr("data-bs-toggle", "collapse")
                                attr("data-bs-target", "#oplog")
                            }) {
                                Text("Operation log")
                            }
                        }
                        Di("accordion-collapse collapse show", {
                            id("oplog")
                        }) {
                            Di("accordion-body") {
                                ResponsiveTable {
                                    Tbody {
                                        for (l in log) {
                                            Tr {
//                            Td { Text(l.id.toString()) }
                                                Td({
                                                    style {
                                                        whiteSpace("nowrap")
                                                    }
                                                }) { Text(l.createdAt.shortString()) }
                                                Td { Text(l.text) }
                                                Td {
                                                    l.stackTrace?.let { stack ->
                                                        Pre { Text(stack) }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    Di("alert alert-secondary mt-2") {
                        Text("log is empty")
                    }
                }
            }
        }
                ?: WaitPanel("загрузка операции")

            LaunchedEffect(true) {
                try {
                    operation = client.getOperation(Router.longParam(0))
                        .also { op ->
                            launch {
                                // it is in most recent first order, so we update it to normal:
                                client.operationLog(op).collect { log.add(0, it) }
                            }
                            launch {
                                contract = client.call(client.api.getOperationContract, op.id)
                                println("**** contract size: ${contract?.size}")
                                contractChecked = true
                            }
                        }
                } catch (x: NotFoundException) {
                    Toaster.warning("операция не найдена")
                    Router.back()
                } catch (t: Throwable) {
                    Toaster.error("ошибка доступа: $t", "операция недоступна")
                    Router.back()
                }
            }
        }
    }
