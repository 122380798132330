package api

import kotlinx.serialization.Serializable

@Serializable
class ApiUserDetails(
    val loginName: String,
    val name: String,
    val nameIsApproved: Boolean = false,
    val canAdmin: Boolean = false,
    val canApproveLoan: Boolean = false,
    val id: Long = 0,
    val canPerformImport: Boolean = false
) {
    val asUser by lazy { ApiUser(name, nameIsApproved, canApproveLoan, canAdmin, id) }
}
