package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLTableCellElement
import org.w3c.dom.HTMLTableElement

@Composable
fun PlainTable(attrs: AttrBuilderContext<HTMLTableElement>? = null, contents: ContentBuilder<HTMLTableElement>) {
    Table({
        attrs?.invoke(this)
        classes("table")
    }, contents)
}

@Composable
fun ResponsiveTable(attrs: AttrBuilderContext<HTMLTableElement>? = null, contents: ContentBuilder<HTMLTableElement>) {
    Di("table-responsive-sm") {
        Table({
            attrs?.invoke(this)
            classes("table")
        }, contents)
    }
}

@Composable
fun propTable(attrs: AttrBuilderContext<HTMLTableElement>? = null, contents: ContentBuilder<HTMLTableElement>) {
    Table({
        attrs?.invoke(this)
    }, contents)
}

@Composable
fun prop(title: String, icon: Icon? = null, builder: ContentBuilder<HTMLTableCellElement>) {
    Tr {
        Th({
            classes("text-right", "pe-3")
        }) {
            Text(title)
            icon?.render()
        }
        Td {
            builder()
        }
    }
}