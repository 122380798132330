package controls

import androidx.compose.runtime.Composable
import api.ApiUser
import client
import org.jetbrains.compose.web.dom.B
import org.jetbrains.compose.web.dom.Text

@Composable
fun UserBlock(au: ApiUser) {
    if (client.currentUser?.id == au.id)
        B { Text("Вы: ") }
    Text("${au.name} (#${au.id})")
}