package views

import androidx.compose.runtime.*
import controls.classNames
import net.sergeych.mptools.toDump
import net.sergeych.unikrypto.toUint8Array
import org.jetbrains.compose.web.dom.*
import platform.Uni

@Composable
fun contractDetails(packedContract: ByteArray,showHeader: Boolean = true) {
    var showBinary by remember { mutableStateOf(false) }

    if( showHeader )
        H5 { Text("Smart contract:") }

    Ul({ classNames("nav nav-tabs") }) {
        Li({ classNames("nav-item") }) {
            A("", {
                classNames(
                    if (showBinary) "nav-link active" else "nav-link"
                )
                onClick { ev ->
                    showBinary = true
                    ev.preventDefault()
                    ev.stopPropagation()
                }
            }) { Text("Binary dump") }
        }
        Li({ classNames("nav-item") }) {
            A("", {
                classNames(
                    if (!showBinary) "nav-link active" else "nav-link"
                )
                onClick { ev ->
                    showBinary = false
                    ev.preventDefault()
                    ev.stopPropagation()
                }

            }) { Text("Structure") }
        }
    }
    if (showBinary) {
            Pre {
                Text(packedContract.toDump())
            }
    }
    else {
        val contract = Uni.TransactionPack.unpack(packedContract.toUint8Array()).contract.capsule.contract
        H6 { Text("Definition")}
        Pre{Text(JSON.stringify(contract.definition.data, null, 4))}
        H6 { Text("State")}
        Pre { Text(JSON.stringify(contract.state.data, null, 4)) }
        H6 { Text("Transactional data")}
        Pre { Text(JSON.stringify(contract.transactional, null, 4)) }
    }
}
