package views

import LoggedIn
import Router
import androidx.compose.runtime.*
import api.ApiUser
import backHeader
import client
import controls.*
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.Text

@Composable
fun ImportFunds() {
    var user by remember { mutableStateOf(ApiUser.empty) }
    var text by remember { mutableStateOf("") }
    var amount by remember { mutableStateOf("") }
    var busy by remember { mutableStateOf(false) }

    LoggedIn {
//        CenteredNarrowForm {
        if (busy) {
            WaitPanel(
                "Minting",
                "token will be issued to ${user.name}"
            )
        } else {
            backHeader("Exchange: Import")
            Di("alert alert-primary") {
                Text("""This operation is performed by the exchange office where some liquidity/actives 
                    |are exchanged to the system tokens.""".trimMargin())
            }
            val id = SelectUserGroup("User", user) { x ->
                user = x ?: ApiUser.empty
            }
            TextField(amount, "amount") {
                if (numberOrNull(it) != null) amount = it
            }
            TextField(text, "notes (optional)") { text = it }
            Bn({
                classNames("me-2 btn-warning")
                val a = numberOrNull(amount)
                if (a == null || a < 0) disabled()
                onClick {
                    busy = true
                    globalLaunch {
                        try {
                            if (a != null && a > 0) {
                                client.requestImport(user, a, text)
                                Toaster.message("Token generation request is created")
                                Router.back()
                            }
                        } catch (t: Throwable) {
                            t.printStackTrace()
                            Toaster.error(t)
                        }
                        busy = false
                    }
                }
            }) {
                Text("Mint token")
            }
        }
    }

//    }
}