package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.Element
import org.w3c.dom.HTMLDivElement
import tools.csplit

@Composable
fun Di(classNames: String,attrBuilder: AttrBuilderContext<HTMLDivElement>?=null,contentBuilder: ContentBuilder<HTMLDivElement>) {
    Div({
        classes(*classNames.split(' ').filter { it.isNotEmpty() }.toTypedArray())
        attrBuilder?.invoke(this)
    }, contentBuilder)
}

fun <T: Element>AttrsScope<T>.classNames(names: String) {
    classes(names.csplit)
}