package tools

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import net.sergeych.mp_logger.LogTag
import net.sergeych.mptools.Now
import kotlin.time.Duration

/**
 * Class to delayed ativate a block after a timeout after a [schedule] was called.
 *
 * known problems:
 *
 * - if start is called repeatedly and fast, the block will never been called, we need another
 * parameter to control it.
 *
 * - [executeNow] could actually wait up to [pause] time, we need to find a way to gracefully cancel delay
 */
class Debouncer(val scope: CoroutineScope, val pause: Duration, block: suspend () -> Unit) : LogTag("DEBNCR") {

    private var activateAt: Instant? = null

    fun schedule() {
        activateAt = Now() + pause
    }

    fun executeNow() {
        activateAt = Now()
    }

    init {
        scope.launch {
            while (true) {
                val left = activateAt?.let { t ->
                    t - Now()
                } ?: pause
//                if (left != pause) debug { "extra pause $left" }
                delay(left)
                val at = activateAt
                if (at != null) {
                    if (at <= Now()) {
                        block()
                        activateAt = null
                    }
                }
            }
        }
    }

}

@Composable
fun rememberDebouncer(pause: Duration, block: suspend () -> Unit): Debouncer {
    val scope = rememberCoroutineScope()
    return remember { Debouncer(scope, pause, block) }
}
