package controls

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import tools.randomId

/**
 * Render some div with a bootstrap tooltip properly. Important:
 * __it sets the div's `id` -- ovveriding it if need__. If you need this id, it is returned.
 * It uses `div` with `display: inline` to integrate the tooltip.
 *
 * @return random html element id assigned to this element
 */
@Composable
fun WithTooltip(
    tooltipText: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    builder: ContentBuilder<HTMLDivElement>
): String {
    val divId = remember { randomId(9) }
    Div({
        attrs?.invoke(this)
        id(divId)
        attr("data-bs-toggle", "tooltip")
        attr("data-bs-title", tooltipText)
        title(tooltipText + "(1)")
        style { display(DisplayStyle.Inline) }
    }, builder)

    LaunchedEffect(true) {
        js("bootstrap.Tooltip.getOrCreateInstance('#'+divId)")
    }
    return divId
}