package api

import kotlinx.serialization.Serializable

@Serializable
data class ApiUser(
    val name: String,
    val nameIsApproved: Boolean = false,
    val canApproveLoan: Boolean = false,
    val canAdmin: Boolean = false,
    val id: Long = 0
) {
    val isEmpty = name.isBlank() || id == 0L

    companion object {
        val empty = ApiUser("")
    }
}